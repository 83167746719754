export const Types = {
  GET_STATES: "GET_STATES",
  GET_STATES_SUCCESS: "GET_STATES_SUCCESS",
  GET_STATES_ERROR: "GET_STATES_ERROR"
};

const INIT_STATE = {
  list: [],
  errors: [],
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.GET_STATES:
      return {
        ...state,
        loading: true,
        error: ""
      };

    case Types.GET_STATES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        errors: ""
      };

    case Types.GET_STATES_ERROR:
      return {
        ...state,
        loading: false,
        list: [],
        errors: action.payload.errors
      };

    default:
      return { ...state };
  }
};

export const getAllStates = () => ({
  type: Types.GET_STATES
});

export const Types = {
  GET_CLIENTS_REQUEST: 'GET_CLIENTS_REQUEST',
  GET_CLIENTS_SUCCESS: 'GET_CLIENTS_SUCCESS',
  GET_CLIENTS_FAILURE: 'GET_CLIENTS_FAILURE',
}


const initialState = {
  list:[],
  loading:false,
  error:null,
  clientCount: 0
}

export default (state = initialState, action) => {
  switch (action.type){
    case Types.GET_CLIENTS_REQUEST:
    return {
      ...state,
      loading:true,
    }
    case Types.GET_CLIENTS_SUCCESS:
    return {
      ...state,
      list: action.payload.clientList,
      clientCount: action.payload.clientCount,
      loading:false,
      error:null,
    }

    case Types.GET_CLIENTS_FAILURE:
    return {
      ...state,
      loading:false,
    }

    default: return state
  }
}

export const actions = {
  GetClientsRequest: (parameters) => {

      return {
        type: Types.GET_CLIENTS_REQUEST,
        payload: parameters,
      }
    },

    GetClientsSuccess: ({clientList, clientCount}) => {
      return {
        type: Types.GET_CLIENTS_SUCCESS,
        payload: {clientList, clientCount},
      }
    },

    GetClientsFailure: (errorMessage) => {
      return {
        type: Types.GET_CLIENTS_FAILURE,
        payload: errorMessage,
      }
    },

}
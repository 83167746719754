import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { Types } from "./index";
import { Alert } from "../../helpers/Alert";
import {
  addHoliday,
  getHolidays,
  getUniqueHoliday,
  updateHoliday,
  deleteHoliday
} from "../../services/http";

function* addHolidayAsync({ payload }) {
  try {
    const { redirectToBack } = payload;
    const response = yield call(addHoliday, payload);
    yield put({
      type: Types.ADD_HOLIDAY_SUCCESS,
      payload: {
        holiday: response.data.data
      }
    });
    Alert({
      type: "success",
      title: "Feriado cadastrado com sucesso!"
    }).then(() => redirectToBack());
  } catch (error) {
    yield put({
      type: Types.ADD_HOLIDAY_ERROR,
      payload: {
        error: error.response
      }
    });
    Alert({
      type: "error",
      title:
        error?.response?.data?.errors[0]?.message ||
        "Erro no cadastro de feriado",
      timer: 2000
    });
  }
}

function* getHolidaysAsync({ payload }) {
  try {
    const response = yield call(getHolidays, payload);
    yield put({
      type: Types.GET_HOLIDAYS_SUCCESS,
      payload: {
        holidayList: response.data.data,
        count: response.data.count
      }
    });
  } catch (error) {
    yield put({
      type: Types.ADD_HOLIDAY_ERROR,
      payload: {
        error
      }
    });
  }
}

function* getUniqueHolidayAsync({ payload }) {
  try {
    const { holidayList } = yield select(state => state.holiday);

    if (holidayList.length !== 0) {
      const uniqueHoliday = holidayList.filter(h => h.id == payload.id);

      yield put({
        type: Types.GET_UNIQUE_HOLIDAY_SUCCESS,
        payload: {
          uniqueHoliday: uniqueHoliday[0]
        }
      });
    } else {
      const response = yield call(getUniqueHoliday, payload);

      yield put({
        type: Types.GET_UNIQUE_HOLIDAY_SUCCESS,
        payload: {
          uniqueHoliday: response?.data?.data
        }
      });
    }
  } catch (error) {
    yield put({
      type: Types.GET_UNIQUE_HOLIDAY_ERROR,
      payload: {
        error: error?.response?.data
      }
    });
  }
}

function* updateHolidayAsync({ payload }) {
  try {
    const response = yield call(updateHoliday, payload);
    yield put({
      type: Types.UPDATE_HOLIDAY_SUCCESS,
      payload: {
        holiday: response.data.data
      }
    });
    Alert({
      type: "success",
      title: "Feriado alterado com sucesso!"
    }).then(() => payload.history.goBack());
  } catch (error) {
    yield put({
      type: Types.UPDATE_HOLIDAY_ERROR,
      payload: {
        error: error?.response
      }
    });
    Alert({
      type: "error",
      title:
        error?.response?.data?.errors[0]?.message ||
        "Erro na alteração de feriado",
      timer: 2000
    });
  }
}

function* deleteHolidayAsync({ payload }) {
  try {
    yield call(deleteHoliday, payload);
    yield put({
      type: Types.DELETE_HOLIDAY_SUCCESS,
      payload: {
        id: payload.id
      }
    });
    Alert({
      type: "success",
      title: "Feriado removido com sucesso!"
    });
  } catch (error) {
    yield put({
      type: Types.DELETE_HOLIDAY_ERROR,
      payload: {
        error: error?.response
      }
    });
    Alert({
      type: "error",
      title:
        error?.response?.data?.errors[0]?.message ||
        "Erro na remoção do feriado!",
      timer: 2000
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(Types.ADD_HOLIDAY, addHolidayAsync),
    yield takeLatest(Types.GET_HOLIDAYS, getHolidaysAsync),
    yield takeLatest(Types.GET_UNIQUE_HOLIDAY, getUniqueHolidayAsync),
    yield takeLatest(Types.UPDATE_HOLIDAY, updateHolidayAsync),
    yield takeLatest(Types.DELETE_HOLIDAY, deleteHolidayAsync)
  ]);
}

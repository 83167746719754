import { all, call, put, takeLatest } from "redux-saga/effects";
import { Types } from "./index";
import { Alert } from '../../helpers/Alert';
import FormattErrors from '../../helpers/Errors';

import {
  getDevices,
  getDeviceManufacturer,
  addDeviceManufacturer,
  updateDeviceManufacturer,
  deleteDeviceManufacturer,
  addDevice,
  updateDevice,
  removeDevice
} from "../../services/http/index";

function* getDevicesAsync({ payload }) {
  try {
    const response = yield call(getDevices, payload);
    yield put({
      type: Types.GET_DEVICES_SUCCESS,
      payload: {
        list: response.data.data,
        devicesCount: response.data.count
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_DEVICES_ERROR,
      payload: {
        errors: error
      }
    });
    Alert({
      type: "error",
      title: FormattErrors(error)
    })
  }
}

function* addDeviceAsync({ payload }) {
  try {
    const { device, redirectToList } = payload
    const response = yield call(addDevice, device);
    yield put({
      type: Types.ADD_DEVICES_SUCCESS,
      payload: {
        list: response.data.data
      }
    });
    Alert({
      type: "success",
      title: "Dispositivo adicionado com sucesso!"
    }).then(() => redirectToList())
  } catch (error) {
    yield put({
      type: Types.ADD_DEVICES_ERROR,
      payload: {
        errors: error
      }
    });
    Alert({
      type: "error",
      title: FormattErrors(error)
    })
  }
}

function* updateDeviceAsync({ payload }) {
  try {
    const { device, redirectToList } = payload;
    const response = yield call(updateDevice, device);
    yield put({
      type: Types.UPDATE_DEVICES_SUCCESS,
      payload: {
        list: response.data.data
      }
    });
    Alert({
      type: "success",
      title: "Dispositivo atualizado com sucesso!"
    }).then(() => redirectToList())
  } catch (error) {
    yield put({
      type: Types.UPDATE_DEVICES_ERROR,
      payload: {
        errors: error
      }
    });
    Alert({
      type: "error",
      title: FormattErrors(error)
    })
  }
}

function* deleteDeviceAsync({ payload }) {
  try {
    console.log(payload)
    const response = yield call(removeDevice, payload);
    yield put({
      type: Types.DELETE_DEVICES_SUCCESS,
      payload: {
        list: response.data.data
      }
    });
    Alert({
      type: "success",
      title: "Dispositivo removido com sucesso!"
    })
  } catch (error) {
    yield put({
      type: Types.DELETE_DEVICES_ERROR,
      payload: {
        errors: error
      }
    });
    Alert({
      type: "error",
      title: FormattErrors(error)
    })
  }
}

function* getDevicesManufacturerAsync({ payload }) {
  try {
    const response = yield call(getDeviceManufacturer, payload);
    yield put({
      type: Types.GET_DEVICES_MANUFACTURER_SUCCESS,
      payload: {
        list: response.data.data,
        devicesBrandCount: response.data.count
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_DEVICES_MANUFACTURER_ERROR,
      payload: {
        errors: error
      }
    });
    Alert({
      type: "error",
      title: FormattErrors(error)
    })
  }
}

function* addDevicesManufacturerAsync({ payload }) {
  try {
    const { redirectToList } = payload;
    const response = yield call(addDeviceManufacturer, payload);
    yield put({
      type: Types.ADD_DEVICES_MANUFACTURER_SUCCESS,
      payload: {
        list: response.data.data
      }
    });
    Alert({
      type: "success",
      title: "Marca adicionada com sucesso!"
    }).then(() => redirectToList())
  } catch (error) {
    yield put({
      type: Types.ADD_DEVICES_MANUFACTURER_ERROR,
      payload: {
        errors: error
      }
    });
    Alert({
      type: "error",
      title: FormattErrors(error)
    })
  }
}

function* updateDevicesManufacturerAsync({ payload }) {
  try {
    const { redirectToList } = payload;
    const response = yield call(updateDeviceManufacturer, payload);
    yield put({
      type: Types.UPDATE_DEVICES_MANUFACTURER_SUCCESS,
      payload: {
        list: response.data.data
      }
    });
    Alert({
      type: "success",
      title: "Marca atualizada com sucesso!"
    }).then(() => redirectToList())
  } catch (error) {
    yield put({
      type: Types.UPDATE_DEVICES_MANUFACTURER_ERROR,
      payload: {
        errors: error
      }
    });
    Alert({
      type: "error",
      title: FormattErrors(error)
    })
  }
}

function* deleteDevicesManufacturerAsync({ payload }) {
  try {
    const response = yield call(deleteDeviceManufacturer, payload);
    yield put({
      type: Types.DELETE_DEVICES_MANUFACTURER_SUCCESS,
      payload: {
        list: response.data.data
      }
    });
    Alert({
      type: "success",
      title: "Marca removida com sucesso!"
    })
  } catch (error) {
    yield put({
      type: Types.DELETE_DEVICES_MANUFACTURER_ERROR,
      payload: {
        errors: error
      }
    });
    Alert({
      type: "error",
      title: FormattErrors(error)
    })
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(Types.GET_DEVICES, getDevicesAsync),
    yield takeLatest(
      Types.GET_DEVICES_MANUFACTURER,
      getDevicesManufacturerAsync
    ),
    yield takeLatest(
      Types.ADD_DEVICES_MANUFACTURER,
      addDevicesManufacturerAsync
    ),
    yield takeLatest(
      Types.UPDATE_DEVICES_MANUFACTURER,
      updateDevicesManufacturerAsync
    ),
    yield takeLatest(
      Types.DELETE_DEVICES_MANUFACTURER,
      deleteDevicesManufacturerAsync
    ),
    yield takeLatest(Types.ADD_DEVICES, addDeviceAsync),
    yield takeLatest(Types.UPDATE_DEVICES, updateDeviceAsync),
    yield takeLatest(Types.DELETE_DEVICES, deleteDeviceAsync)
  ]);
}

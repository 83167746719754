import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import authUser from "./auth";
import user from "./user";
import device from "./device";
import townHall from "./townhall";
import states from "./states";
import profile from "./profile";
import establishment from "./establishment";
import address from "./address";
import region from "./region";
import holiday from "./holiday";
import irregularity from "./irregularity";
import clients from './clients'

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  user,
  device,
  townHall,
  states,
  profile,
  establishment,
  address,
  region,
  holiday,
  irregularity,
  clients
});

export default reducers;

export const Types = {
  GET_ADDRESS: "address/GET_ADDRESS",
  GET_ADDRESS_SUCCESS: "address/GET_ADDRESS_SUCCESS",
  GET_ADDRESS_ERROR: "address/GET_ADDRESS_ERROR",

  RESET_ADDRESS: "address/RESET_ADDRESS"
}

const INITIAL_STATE = {
  endereco: "",
  bairro: "",
  cidade: "",
  uf: "",
  loading: false,
  getAddressSuccess: false,
  getAddressError: false
}

export default function AddressReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case Types.GET_ADDRESS:   
      return {
        ...state,
        loading: true,
        getAddressSuccess: false,
        getAddressError: false
      }

    case Types.GET_ADDRESS_SUCCESS:   
      return {
        ...state,
        endereco: action.payload.endereco,
        bairro: action.payload.bairro,
        cidade: action.payload.cidade,
        uf: action.payload.uf,
        ibgeCode: action.payload.ibgeCode,
        loading: false,
        getAddressSuccess: true,
        getAddressError: false
      }

    case Types.GET_ADDRESS_ERROR:   
      return {
        ...state,
        loading: false,
        getAddressSuccess: false,
        getAddressError: true
      }

    case Types.RESET_ADDRESS:   
      return {
        ...state,
        endereco: "",
        bairro: "",
        cidade: "",
        uf: "",
      }


    default: 
      return {
        ...state
      }
  }
}

export const getAddress = ({ cep }) => {
  return {
    type: Types.GET_ADDRESS,
    payload: {
      cep
    }
  }
}

export const resetAddress = () => {
  return {
    type: Types.RESET_ADDRESS
  }
}
export const Types = {
  GET_ESTABLISHMENTS: "establishment/GET_ESTABLISHMENTS",
  GET_ESTABLISHMENTS_SUCCESS: "establishment/GET_ESTABLISHMENTS_SUCCESS",
  GET_ESTABLISHMENTS_ERROR: "establishment/GET_ESTABLISHMENTS_ERROR",

  ADD_ESTABLISHMENT: "establishment/ADD_ESTABLISHMENT",
  ADD_ESTABLISHMENT_SUCCESS: "establishment/ADD_ESTABLISHMENT_SUCCESS",
  ADD_ESTABLISHMENT_ERROR: "establishment/ADD_ESTABLISHMENT_ERROR",

  GET_UNIQUE_ESTABLISHMENT: "establishment/GET_UNIQUE_ESTABLISHMENT",
  GET_UNIQUE_ESTABLISHMENT_SUCCESS: "establishment/GET_UNIQUE_ESTABLISHMENT_SUCCESS",
  GET_UNIQUE_ESTABLISHMENT_ERROR: "establishment/GET_UNIQUE_ESTABLISHMENT_ERROR",

  EDIT_ESTABLISHMENT: "establishment/EDIT_ESTABLISHMENT",
  EDIT_ESTABLISHMENT_SUCCESS: "establishment/EDIT_ESTABLISHMENT_SUCCESS",
  EDIT_ESTABLISHMENT_ERROR: "establishment/EDIT_ESTABLISHMENT_ERROR",

  REMOVE_ESTABLISHMENT: "establishment/REMOVE_ESTABLISHMENT",
  REMOVE_ESTABLISHMENT_SUCCESS: "establishment/REMOVE_ESTABLISHMENT_SUCCESS",
  REMOVE_ESTABLISHMENT_ERROR: "establishment/REMOVE_ESTABLISHMENT_ERROR",

  GET_ESTABLISHMENT_EMPLOYEES: "establishment/GET_ESTABLISHMENT_EMPLOYEES",
  GET_ESTABLISHMENT_EMPLOYEES_SUCCESS: "establishment/GET_ESTABLISHMENT_EMPLOYEES_SUCCESS",
  GET_ESTABLISHMENT_EMPLOYEES_ERROR: "establishment/GET_ESTABLISHMENT_EMPLOYEES_ERROR",

  ADD_ESTABLISHMENT_EMPLOYEES: "establishment/ADD_ESTABLISHMENT_EMPLOYEES",
  ADD_ESTABLISHMENT_EMPLOYEES_SUCCESS: "establishment/ADD_ESTABLISHMENT_EMPLOYEES_SUCCESS",
  ADD_ESTABLISHMENT_EMPLOYEES_ERROR: "establishment/ADD_ESTABLISHMENT_EMPLOYEES_ERROR",

  REMOVE_ESTABLISHMENT_EMPLOYEES: "establishment/REMOVE_ESTABLISHMENT_EMPLOYEES",
  REMOVE_ESTABLISHMENT_EMPLOYEES_SUCCESS: "establishment/REMOVE_ESTABLISHMENT_EMPLOYEES_SUCCESS",
  REMOVE_ESTABLISHMENT_EMPLOYEES_ERROR: "establishment/REMOVE_ESTABLISHMENT_EMPLOYEES_ERROR",

  RESET_ESTABLISHMENT_STATUS: "establishment/RESET_ESTABLISHMENT_STATUS",

  // Histórico de compra
  GET_MASTER_ESTABLISHMENT_CADS_PURCHASE_HISTORY: "establishment/GET_MASTER_ESTABLISHMENT_CADS_PURCHASE_HISTORY",
  GET_MASTER_ESTABLISHMENT_CADS_PURCHASE_HISTORY_SUCCESS: "establishment/GET_MASTER_ESTABLISHMENT_CADS_PURCHASE_HISTORY_SUCCESS",
  GET_MASTER_ESTABLISHMENT_CADS_PURCHASE_HISTORY_ERROR: "establishment/GET_MASTER_ESTABLISHMENT_CADS_PURCHASE_HISTORY_ERROR",

  GET_MASTER_ESTABLISHMENT_CADS_SELL_HISTORY: "establishment/GET_MASTER_ESTABLISHMENT_CADS_SELL_HISTORY",
  GET_MASTER_ESTABLISHMENT_CADS_SELL_HISTORY_SUCCESS: "establishment/GET_MASTER_ESTABLISHMENT_CADS_SELL_HISTORY_SUCCESS",
  GET_MASTER_ESTABLISHMENT_CADS_SELL_HISTORY_ERROR: "establishment/GET_MASTER_ESTABLISHMENT_CADS_SELL_HISTORY_ERROR"
};

const INIT_STATE = {
  list: [],
  listEmployees: [],
  listEmployeesCount: 0,
  selectedEstablishment: {},
  establishmentsCount: 0,
  masterEstablishmentCadsPurchaseHistory: [],
  masterEstablishmentCadsPurchaseHistoryCount: 0,
  masterEstablishmentCadsSellHistory: [],
  masterEstablishmentCadsSellHistoryCount: 0,
  loading: false,
  getEstablishmentsSuccess: false,
  getEstablishmentsError: false,
  addEstablishmentSuccess: false,
  addEstablishmentError: false,
  getUniqueEstablishmentSuccess: false,
  getUniqueEstablishmentError: false,
  editEstablishmentSuccess: false,
  editEstablishmentError: false,
  removeEstablishmentSuccess: false,
  removeEstablishmentError: false,
  addEstablishmentEmployeesSuccess: false,
  addEstablishmentEmployeesError: false,
  removeEstablishmentEmployeesSuccess: false,
  removeEstablishmentEmployeesError: false,
  getEstablishmentEmployeesSuccess: false,
  getEstablishmentEmployeesError: false,
  getMasterEstablishmentCadsError: false,
  getMasterEstablishmentCadsSuccess: false,
  getMasterEstablishmentCadsSellError: false,
  getMasterEstablishmentCadsSellSuccess: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.GET_ESTABLISHMENTS:
      return {
        ...state,
        loading: true,
        getEstablishmentsSuccess: false,
        getEstablishmentsError: false,
      }

    case Types.GET_ESTABLISHMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        establishmentsCount: action.payload.establishmentsCount,
        getEstablishmentsSuccess: true,
        getEstablishmentsError: false,
      }

    case Types.GET_ESTABLISHMENTS_ERROR:
      return {
        ...state,
        loading: false,
        getEstablishmentsSuccess: false,
        getEstablishmentsError: true,
      }

    case Types.ADD_ESTABLISHMENT:
      return {
        ...state,
        loading: true,
        addEstablishmentSuccess: false,
        addEstablishmentError: false,
      }

    case Types.ADD_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        addEstablishmentSuccess: true,
        addEstablishmentError: false,
      }

    case Types.ADD_ESTABLISHMENT_ERROR:
      return {
        ...state,
        loading: false,
        addEstablishmentSuccess: false,
        addEstablishmentError: true,
      }

    case Types.GET_UNIQUE_ESTABLISHMENT:
      return {
        ...state,
        loading: true,
        getUniqueEstablishmentSuccess: false,
        getUniqueEstablishmentError: false,
      }

    case Types.GET_UNIQUE_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        selectedEstablishment: action.payload.selectedEstablishment,
        loading: false,
        getUniqueEstablishmentSuccess: true,
        getUniqueEstablishmentError: false,
      }

    case Types.GET_UNIQUE_ESTABLISHMENT_ERROR:
      return {
        ...state,
        loading: false,
        getUniqueEstablishmentSuccess: false,
        getUniqueEstablishmentError: true,
      }

    case Types.EDIT_ESTABLISHMENT:
      return {
        ...state,
        loading: true,
        editEstablishmentSuccess: false,
        editEstablishmentError: false,
      }

    case Types.EDIT_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        editEstablishmentSuccess: true,
        editEstablishmentError: false,
      }

    case Types.EDIT_ESTABLISHMENT_ERROR:
      return {
        ...state,
        loading: false,
        editEstablishmentSuccess: false,
        editEstablishmentError: true,
      }

    case Types.REMOVE_ESTABLISHMENT:
      return {
        ...state,
        loading: true,
        removeEstablishmentSuccess: false,
        removeEstablishmentError: false,
      }

    case Types.REMOVE_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        removeEstablishmentSuccess: true,
        removeEstablishmentError: false,
      }

    case Types.REMOVE_ESTABLISHMENT_ERROR:
      return {
        ...state,
        loading: false,
        removeEstablishmentSuccess: false,
        removeEstablishmentError: true,
      }

    case Types.ADD_ESTABLISHMENT_EMPLOYEES:
      return {
        ...state,
        loading: true,
        addEstablishmentEmployeesSuccess: false,
        addEstablishmentEmployeesError: false,
      }

    case Types.ADD_ESTABLISHMENT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        addEstablishmentEmployeesSuccess: true,
        addEstablishmentEmployeesError: false,
      }

    case Types.ADD_ESTABLISHMENT_EMPLOYEES_ERROR:
      return {
        ...state,
        loading: false,
        addEstablishmentEmployeesSuccess: false,
        addEstablishmentEmployeesError: true,
      }

    case Types.REMOVE_ESTABLISHMENT_EMPLOYEES:
      return {
        ...state,
        loading: true,
        removeEstablishmentEmployeesSuccess: false,
        removeEstablishmentEmployeesError: false,
      }

    case Types.REMOVE_ESTABLISHMENT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        removeEstablishmentEmployeesSuccess: true,
        removeEstablishmentEmployeesError: false,
      }

    case Types.REMOVE_ESTABLISHMENT_EMPLOYEES_ERROR:
      return {
        ...state,
        loading: false,
        removeEstablishmentEmployeesSuccess: false,
        removeEstablishmentEmployeesError: true,
      }

    case Types.GET_ESTABLISHMENT_EMPLOYEES:
      return {
        ...state,
        loading: true,
        getEstablishmentEmployeesSuccess: false,
        getEstablishmentEmployeesError: false,
      }

    case Types.GET_ESTABLISHMENT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        listEmployees: action.payload.listEmployees,
        listEmployeesCount: action.payload.listEmployeesCount,
        getEstablishmentEmployeesSuccess: true,
        getEstablishmentEmployeesError: false,
      }

    case Types.GET_ESTABLISHMENT_EMPLOYEES_ERROR:
      return {
        ...state,
        loading: false,
        getEstablishmentEmployeesSuccess: false,
        getEstablishmentEmployeesError: true,
      }

    case Types.GET_MASTER_ESTABLISHMENT_CADS_PURCHASE_HISTORY:
      return {
        ...state,
        loading: true,
        getMasterEstablishmentCadsError: false,
        getMasterEstablishmentCadsSuccess: false,
      }

    case Types.GET_MASTER_ESTABLISHMENT_CADS_PURCHASE_HISTORY_ERROR:
      return {
        ...state,
        loading: true,
        getMasterEstablishmentCadsError: true,
        getMasterEstablishmentCadsSuccess: false,
      }

    case Types.GET_MASTER_ESTABLISHMENT_CADS_PURCHASE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: true,
        getMasterEstablishmentCadsError: false,
        getMasterEstablishmentCadsSuccess: true,
        masterEstablishmentCadsPurchaseHistory: action.payload.masterEstablishmentCadsPurchaseHistory,
        masterEstablishmentCadsPurchaseHistoryCount: action.payload.masterEstablishmentCadsPurchaseHistoryCount
      }

    case Types.GET_MASTER_ESTABLISHMENT_CADS_SELL_HISTORY:
      return {
        ...state,
        loading: true,
        getMasterEstablishmentCadsSellError: false,
        getMasterEstablishmentCadsSellSuccess: false,
      }

    case Types.GET_MASTER_ESTABLISHMENT_CADS_SELL_HISTORY_ERROR:
      return {
        ...state,
        loading: true,
        getMasterEstablishmentCadsSellError: true,
        getMasterEstablishmentCadsSellSuccess: false,
      }

    case Types.GET_MASTER_ESTABLISHMENT_CADS_SELL_HISTORY_SUCCESS:
      return {
        ...state,
        loading: true,
        getMasterEstablishmentCadsSellError: false,
        getMasterEstablishmentCadsSellSuccess: true,
        masterEstablishmentCadsSellHistory: action.payload.masterEstablishmentCadsSellHistory,
        masterEstablishmentCadsSellHistoryCount: action.payload.masterEstablishmentCadsSellHistoryCount
      }

    case Types.RESET_ESTABLISHMENT_STATUS:
      return {
        ...state,
        getEstablishmentsSuccess: false,
        getEstablishmentsError: false,
        addEstablishmentSuccess: false,
        addEstablishmentError: false,
        getUniqueEstablishmentSuccess: false,
        getUniqueEstablishmentError: false,
        editEstablishmentSuccess: false,
        editEstablishmentError: false,
        removeEstablishmentSuccess: false,
        removeEstablishmentError: false,
        addEstablishmentEmployeesSuccess: false,
        addEstablishmentEmployeesError: false,
        getEstablishmentEmployeesSuccess: false,
        getEstablishmentEmployeesError: false,
        getMasterEstablishmentCadsError: false,
        getMasterEstablishmentCadsSuccess: false,
        getMasterEstablishmentCadsSellError: false,
        getMasterEstablishmentCadsSellSuccess: false,
      }

    default:
      return {
        ...state,
      }
  }
}

export const getEstablishments = ({
  page = "",
  pageSize = "",
  codigo = "",
  cnpj = "",
  nomeFantasia = "",
  estadoId = "",
  cidade = "",
  stateName = "" }) => {

  return {
    type: Types.GET_ESTABLISHMENTS,
    payload: {
      codigo,
      page,
      pageSize,
      cnpj,
      nomeFantasia,
      estadoId,
      cidade,
      stateName
    }
  }
}

export const addEstablishment = ({establishment}) => {
  return {
    type: Types.ADD_ESTABLISHMENT,
    payload: {
      establishment
    }
  }
}

export const getUniqueEstablishment = ({ id }) => {
  return {
    type: Types.GET_UNIQUE_ESTABLISHMENT,
    payload: {
      id,
    }
  }
}

export const editEstablishment = ({
  codigo,
  razaoSocial,
  nomeFantasia,
  cnpj,
  email,
  telefone,
  telefone2,
  telefone3,
  cep,
  endereco,
  numero,
  complemento,
  bairro,
  cidade,
  estadoId,
  CodigoIBGE,
  quantidadeMaxima,
  quantidadeMinima,
  master
}) => {
  return {
    type: Types.EDIT_ESTABLISHMENT,
    payload: {
      id: codigo,
      razaoSocial,
      nomeFantasia,
      cnpj,
      email,
      telefone,
      telefone2,
      telefone3,
      cep,
      endereco,
      numero,
      complemento,
      bairro,
      cidade,
      estadoId,
      CodigoIBGE,
      quantidadeMaxima,
      quantidadeMinima,
      master
    }
  }
}

export const removeEstablishment = ({ id }) => {
  return {
    type: Types.REMOVE_ESTABLISHMENT,
    payload: {
      id,
    }
  }
}

export const addEstablishmentEmployees = ({ employees }) => {
  return {
    type: Types.ADD_ESTABLISHMENT_EMPLOYEES,
    payload: {
      employees
    }
  }
}

export const removeEstablishmentEmployees = ({ employees }) => {
  return {
    type: Types.REMOVE_ESTABLISHMENT_EMPLOYEES,
    payload: {
      employees
    }
  }
}

export const getEstablishmentEmployees = ({
  page = "",
  pageSize = "",
  codigo = "",
  nome = "",
  cpf = "",
  email = "",
  estabelecimentoId = ""
}) => {
  return {
    type: Types.GET_ESTABLISHMENT_EMPLOYEES,
    payload: {
      id: codigo,
      page,
      pageSize,
      nome,
      cpf,
      email,
      estabelecimentoId
    }
  }
}

export const resetEstablishmentStatus = () => {
  return {
    type: Types.RESET_ESTABLISHMENT_STATUS,
  }
}

export const getMasterEstablishmentCadsPurchaseHistory = ({
  page = "",
  pageSize = "",
  prefeituraId = "",
  id = ""
}) => {
  return {
    type: Types.GET_MASTER_ESTABLISHMENT_CADS_PURCHASE_HISTORY,
    payload: {
      id,
      page,
      pageSize,
      prefeituraId
    }
  }
}

export const getMasterEstablishmentCadsSellHistory = ({
  page = "",
  pageSize = "",
  prefeituraId = "",
}) => {
  return {
    type: Types.GET_MASTER_ESTABLISHMENT_CADS_SELL_HISTORY,
    payload: {
      page,
      pageSize,
      prefeituraId
    }
  }
}
import { all, call, put, takeLatest } from "redux-saga/effects";
import { Types } from "./index";
import { getIrregularities, getIrregularity, updateIrregularityStatus, getIrregularityOperations } from "../../services/http";

function* getIrregularitiesAsync({ payload }) {
  try {
    const response = yield call(getIrregularities, payload);

    yield put({
      type: Types.GET_IRREGULARITIES_SUCCESS,
      payload: {
        irregularities: response.data.data,
        irregularitiesCount: response.data.count
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_IRREGULARITIES_ERROR,
      payload: {
        error
      }
    });
  }
}

function* getIrregularityOperationsAsync({ payload }) {
  try {
    const response = yield call(getIrregularityOperations, payload);

    yield put({
      type: Types.GET_IRREGULARITY_OPERATIONS_SUCCESS,
      payload: {
        irregularityOperations: response.data.data,
        irregularityOperationsCount: response.data.count
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_IRREGULARITY_OPERATIONS_ERROR,
      payload: {
        error
      }
    });
  }
}

function* getIrregularityAsync({ payload }) {
  try {
    const response = yield call(getIrregularity, payload);

    yield put({
      type: Types.GET_IRREGULARITY_SUCCESS,
      payload: {
        irregularity: response.data.data,
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_IRREGULARITY_ERROR,
      payload: {
        error
      }
    });
  }
}

function* updateIrregularityStatusAsync({ payload }) {
  try {
    yield call(updateIrregularityStatus, payload);

    yield put({
      type: Types.UPDATE_IRREGULARITY_STATUS_SUCCESS,
      payload: {
      }
    });
  } catch (error) {
    yield put({
      type: Types.UPDATE_IRREGULARITY_STATUS_ERROR,
      payload: {
        error
      }
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(Types.GET_IRREGULARITIES, getIrregularitiesAsync),
    yield takeLatest(Types.GET_IRREGULARITY_OPERATIONS, getIrregularityOperationsAsync),
    yield takeLatest(Types.GET_IRREGULARITY, getIrregularityAsync),
    yield takeLatest(Types.UPDATE_IRREGULARITY_STATUS, updateIrregularityStatusAsync),
  ]);
}

/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import axios from "axios";
import moment from "moment";

const baseUrlHom = "http://api2.prefeituras.luby.com.br/api";
const baseUrlProd = "https://appzulapi.azurewebsites.net/api";
const baseUrlHom2 = "http://api.prefeituras.luby.com.br/api";

const api = axios.create({
  baseURL: baseUrlProd
});

const refresh = async refreshData => {
  return api.request({
    method: "POST",
    url: "/token/refresh",
    data: {
      usuarioId: refreshData.userId,
      token: refreshData.refreshToken
    }
  });
};

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  async response => {
    const lastLogin = await localStorage.getItem("lastLogin");

    if (moment(new Date()).diff(moment(lastLogin), "days") > 0) {
      await localStorage.removeItem("token");
      await localStorage.removeItem("refreshToken");
      await localStorage.removeItem("userId");
      await localStorage.removeItem("lastLogin");

      window.location.href = "/";

      return Promise.reject();
    }
    return Promise.resolve(response);
  },
  async error => {
    const originalRequest = error.config;

    if (
      error?.response?.status === 400 &&
      originalRequest === "/token/refresh"
    ) {
      await localStorage.removeItem("token");
      await localStorage.removeItem("refreshToken");
      await localStorage.removeItem("userId");
      await localStorage.removeItem("lastLogin");

      window.location.href = "/";

      return Promise.reject(error);
    }

    if (error?.response?.status === 401) {
      // Deslogar caso passe o dia
      const lastLogin = await localStorage.getItem("lastLogin");

      if (moment(new Date()).diff(moment(lastLogin), "days") > 0) {
        await localStorage.removeItem("token");
        await localStorage.removeItem("refreshToken");
        await localStorage.removeItem("userId");
        await localStorage.removeItem("lastLogin");

        window.location.href = "/";

        return Promise.reject(error);
      }

      if (!originalRequest._retry) {
        originalRequest._retry = true;

        const refreshToken = await localStorage.getItem("refreshToken");
        const userId = await localStorage.getItem("userId");

        try {
          const response = await refresh({
            userId,
            refreshToken
          });

          await localStorage.setItem("token", response.data.access_token);
          await localStorage.setItem(
            "refreshToken",
            response.data[".refreshToken"]
          );

          return api(originalRequest);
        } catch (error) {
          localStorage.clear();
          window.location.href = "/";
          console.log(error);
        }
      }
    }

    return Promise.reject(error);
  }
);

export function login({ email, password }) {
  return api.request({
    method: "post",
    url: `/token`,
    data: {
      email,
      password
    }
  });
}

export function UploadFile({ prefeituraId, tipoArquivo, files }) {
  const data = new FormData();
  data.append("prefeituraId", prefeituraId);
  data.append("tipoArquivo", tipoArquivo);
  data.append("files", files);
  return api.request({
    method: "post",
    url: `/prefeitura/uploadarquivo`,
    data,
    headers: { "Content-Type": "multipart/form-data" }
  });
}

export function DownloadFiles({ chaveBlob, tipoArquivo }) {
  return api.request({
    method: "get",
    url: `/prefeitura/downloadarquivo?chaveBlob=${chaveBlob}&tipoArquivo=${tipoArquivo}`
  });
}

export function getClients({
  page,
  pageSize,
  nome,
  cpf,
  include,
  prefeituraId,
  id,
  status,
  usuarioId,
  telefone
}) {
  return api.request({
    method: "get",
    url: `/carteirausuario?${pageSize && `PageSize=${pageSize}`}&${nome &&
      `Nome=${nome}`}${id && `&Id=${id}`}&${page && `Page=${page}`}&${cpf &&
      `NumeroDocumento=${cpf}`}&${usuarioId &&
      `UsuarioId=${usuarioId}`}&${include &&
      `Include=${include}`}&${prefeituraId &&
      `PrefeituraId=${prefeituraId}`}&Status=${status}&${telefone &&
      `Telefone=${telefone}`}`
  });
}

export function getUsers({
  page,
  pageSize,
  nome,
  codigo,
  cpf,
  estabelecimentoId,
  prefeituraId,
  status
}) {
  return api.request({
    method: "get",
    url: `/usuario?PageSize=${pageSize}&Nome=${nome}&Id=${codigo}&Page=${page}&NumeroDocumento=${cpf}&EstabelecimentoId=${estabelecimentoId}&PrefeituraId=${prefeituraId}&Status=${status}`
  });
}

export function activeUser({ id }) {
  return api.request({
    method: "patch",
    url: `/usuario`,
    data: {
      id,
      status: true
    }
  });
}

export function getDevices({
  pageSize,
  codigo,
  identificadorAparelho,
  tipo,
  modelo,
  page,
  brandName,
  townhall,
  stateName
}) {
  return api.request({
    method: "get",
    url: `/aparelho?PageSize=${pageSize}&IdentificadorAparelho=${identificadorAparelho}&Id=${codigo}&Modelo=${modelo}&Tipo=${tipo}&Page=${page}&NomeFabricante=${brandName}&NomeCidade=${townhall}&NomeEstado=${stateName}`
  });
}

export function addDevice({
  modelo,
  tipo,
  aparelhoFabricanteId,
  identificadorAparelho,
  prefeituraId
}) {
  return api.request({
    method: "post",
    url: `/aparelho`,
    data: {
      modelo,
      tipo,
      aparelhoFabricanteId,
      identificadorAparelho,
      prefeituraId
    }
  });
}

export function removeDevice({ id }) {
  return api.request({
    method: "delete",
    url: `/aparelho/${id}`
  });
}

export function updateDevice({
  codigo,
  aparelhoFabricanteId,
  modelo,
  identificadorAparelho,
  tipo,
  prefeituraId
}) {
  return api.request({
    method: "put",
    url: `/aparelho`,
    data: {
      id: codigo,
      aparelhoFabricanteId,
      modelo,
      identificadorAparelho,
      tipo,
      prefeituraId
    }
  });
}

export function getDeviceManufacturer({ pageSize, nome, codigo, page }) {
  return api.request({
    method: "get",
    url: `/aparelhofabricante?PageSize=${pageSize}&Nome=${nome}&Id=${codigo}&Page=${page}`
  });
}

export function addDeviceManufacturer({ nome }) {
  return api.request({
    method: "post",
    url: `/aparelhofabricante`,
    data: {
      nome
    }
  });
}

export function getTownHalls({
  pageSize = "",
  cod = "",
  ativo = "",
  cidade = "",
  estadoId = "",
  page = "",
  cnpj = "",
  stateName = ""
}) {
  return api.request({
    method: "get",
    url: `/prefeitura?PageSize=${pageSize}&CNPJ=${cnpj}&Id=${cod}&Cidade=${cidade}&EstadoId=${estadoId}&Page=${page}&Status=${ativo}&NomeEstado=${stateName}`
  });
}

export function getAllTownHalls() {
  return api.request({
    method: "get",
    url: `/prefeitura`
  });
}

export function getUniqueTownHall({ id }) {
  return api.request({
    method: "get",
    url: `/prefeitura/${id}`
  });
}

export function getIncludedUsers({
  pageSize,
  townHallId,
  gestor,
  page,
  cod,
  name,
  cpf,
  perfil
}) {
  return api.request({
    method: "get",
    url: `/prefeitura/usuariosvinculados?PageSize=${pageSize}&PrefeituraId=${townHallId}&Gestor=${gestor}&Id=${cod}&Page=${page}&Nome=${name}&NumeroDocumento=${cpf}&Perfil=${perfil}`
  });
}

export function getTownHallUsers({ userId }) {
  return api.request({
    method: "get",
    url: `/prefeitura/usuariosvinculados?UsuarioId=${userId}`
  });
}

export function updateManager({ usuarioId, prefeituraId, gestor }) {
  return api.request({
    method: "put",
    url: `/prefeitura/atualizargestor`,
    data: {
      usuarioId,
      prefeituraId,
      gestor
    }
  });
}

export function updateEstablishmentManager({
  usuarioId,
  estabelecimentoId,
  gestor
}) {
  return api.request({
    method: "put",
    url: `/estabelecimento/atualizargestor`,
    data: {
      usuarioId,
      estabelecimentoId,
      gestor
    }
  });
}

export function updateTownHall(townHall) {
  return api.request({
    method: "put",
    url: "/prefeitura",
    data: townHall
  });
}

export function addTownHall(townHall) {
  return api.request({
    method: "post",
    url: "/prefeitura",
    data: townHall
  });
}

export function addTownHallUsers({ users }) {
  return api.request({
    method: "post",
    url: "/prefeitura/vincularusuarios",
    data: users
  });
}

export function editTownHall({ townHall }) {
  return api.request({
    method: "put",
    url: "/prefeitura",
    data: townHall
  });
}

export function deleteTownHall({ id }) {
  return api.request({
    method: "delete",
    url: `/prefeitura/${id}`
  });
}

export function removeTownHallUsers({ users }) {
  return api.request({
    method: "post",
    url: "/prefeitura/desincularusuarios",
    data: users
  });
}

export function getAllStates(uf = "") {
  return api.request({
    method: "get",
    url: `/estado?UF=${uf}`
  });
}

export function updateDeviceManufacturer({ id, nome }) {
  return api.request({
    method: "put",
    url: `/aparelhofabricante`,
    data: {
      id,
      nome
    }
  });
}

export function deleteDeviceManufacturer({ id }) {
  return api.request({
    method: "delete",
    url: `/aparelhofabricante/${id}`
  });
}

export function getProfilesConfig() {
  return api.request({
    method: "get",
    url: `/perfilacesso/configuracoes`
  });
}

export function getAcessoUser({ userId }) {
  return api.request({
    method: "get",
    url: `/acessousuario?UsuarioId=${userId}`
  });
}

export function getProfiles({ id, page, pageSize, nome, descricao, ativo }) {
  return api.request({
    method: "get",
    url: `/perfilacesso?Id=${id}&PageSize=${pageSize}&Page=${page}&Nome=${nome}&Ativo=${ativo}&Descricao=${descricao}`
  });
}

export function getProfilesSimple() {
  return api.request({
    method: "get",
    url: `/perfilacesso/simple
    `
  });
}

export function getProfile({ id }) {
  return api.request({
    method: "get",
    url: `/perfilacesso/${id}`
  });
}

export function addProfile(profile) {
  return api.request({
    method: "post",
    url: `/perfilacesso`,
    data: profile
  });
}

export function updateProfile(profile) {
  return api.request({
    method: "put",
    url: `/perfilacesso`,
    data: profile
  });
}

export function deleteProfile({ id }) {
  return api.request({
    method: "delete",
    url: `/perfilacesso/${id}`
  });
}

export function addUser(user) {
  return api.request({
    method: "post",
    url: "/usuario",
    data: user
  });
}

export function editUser({ user }) {
  return api.request({
    method: "put",
    url: `/usuario`,
    data: user
  });
}

export function deleteUser({ id }) {
  return api.request({
    method: "delete",
    url: `/usuario/${id}`
  });
}

export function getUniqueUser({ id }) {
  return api.request({
    method: "get",
    url: `/usuario/${id}`
  });
}

export function getEstablishments({
  page,
  pageSize,
  codigo,
  cnpj,
  nomeFantasia,
  estadoId,
  cidade,
  stateName
}) {
  const prefeituraId = localStorage.getItem("lastTownHall");
  return api.request({
    method: "get",
    url: `/estabelecimento?PageSize=${pageSize}&CNPJ=${cnpj}&Id=${codigo}&Page=${page}&NomeFantasia=${nomeFantasia}&EstadoId=${estadoId}&Cidade=${cidade}&NomeEstado=${stateName}&PrefeituraId=${prefeituraId}`
  });
}

export function addEstablishment(establishment) {
  return api.request({
    method: "post",
    url: "/estabelecimento",
    data: establishment
  });
}

export function getUniqueEstablishment({ id }) {
  return api.request({
    method: "get",
    url: `/estabelecimento/${id}`
  });
}

export function editEstablishment(establishment) {
  return api.request({
    method: "put",
    url: "/estabelecimento",
    data: establishment
  });
}

export function removeEstablishment({ id }) {
  return api.request({
    method: "delete",
    url: `/estabelecimento/${id}`
  });
}

export function addEstablishmentEmployees({ employees }) {
  return api.request({
    method: "post",
    url: "/estabelecimento/vincularusuarios",
    data: employees
  });
}

export function removeEstablishmentEmployees({ employees }) {
  return api.request({
    method: "post",
    url: "/estabelecimento/desincularusuarios",
    data: employees
  });
}

export function getEstablishmentEmployees({
  pageSize,
  page,
  id,
  estabelecimentoId,
  nome,
  cpf,
  email
}) {
  return api.request({
    method: "get",
    url: `/estabelecimento/usuariosvinculados?PageSize=${pageSize}&Page=${page}&Id=${id}&Nome=${nome}&Cpf=${cpf}&Email=${email}&EstabelecimentoId=${estabelecimentoId}`
  });
}

export function getAddress({ cep }) {
  return api.request({
    method: "get",
    url: `/cep/${cep}`
  });
}

export function addComission({
  prefeituraId,
  valorPorcentagem,
  dataInicio,
  dataTermino
}) {
  return api.request({
    method: "post",
    url: `/comissaoprefeitura`,
    data: {
      prefeituraId,
      valorPorcentagem,
      dataInicio,
      dataTermino
    }
  });
}

export function getComission({ prefeituraId, skip, take }) {
  return api.request({
    method: "get",
    url: `/comissaoprefeitura?PrefeituraId=${prefeituraId}&Skip=${skip}&Take=${take}`
  });
}

export function addDiscount({
  estabelecimentoId,
  valorPorcentagem,
  dataInicio,
  dataTermino
}) {
  return api.request({
    method: "post",
    url: `/descontoestabelecimento`,
    data: {
      estabelecimentoId,
      valorPorcentagem,
      dataInicio,
      dataTermino
    }
  });
}

export function getDiscount({
  id = "",
  estabelecimentoId = "",
  skip = "",
  take = "",
  startDate = "",
  endDate = ""
}) {
  return api.request({
    method: "get",
    url: `/descontoestabelecimento?Id=${id}&EstabelecimentoId=${estabelecimentoId}&Skip=${skip}&Take=${take}&DataInicio=${startDate}&DataTermino=${endDate}`
  });
}

export function addRegion({
  nome,
  prefeituraId,
  estadoId,
  coordenadas,
  tolerancia,
  ordemPrioridade
}) {
  return api.request({
    method: "post",
    url: `/regiaoprefeitura`,
    data: {
      nome,
      prefeituraId,
      estadoId,
      tolerancia,
      coordenadas,
      ordemPrioridade
    }
  });
}

export function getRegions({ id, prefeituraId, estadoId, page, pageSize }) {
  return api.request({
    method: "get",
    url: `/regiaoprefeitura?Id=${id}&PrefeituraId=${prefeituraId}&Page=${page}&PageSize=${pageSize}`
  });
}

export function addHoliday({
  tipo,
  estadoId,
  prefeituraId,
  descricao,
  tipoData,
  dataInicio,
  dataTermino
}) {
  return api.request({
    method: "post",
    url: `/feriado`,
    data: {
      tipo,
      estadoId,
      prefeituraId,
      descricao,
      tipoData,
      dataInicio,
      dataTermino
    }
  });
}

export function getHolidays({
  pageSize = "",
  page = "",
  stateId = "",
  townhallId = "",
  initialDate = "",
  endDate = "",
  townhallName = "",
  holidayType = "",
  description = ""
}) {
  return api.request({
    method: "get",
    url: `/feriado?PageSize=${pageSize}&Page=${page}&EstadoId=${stateId}&PrefeituraId=${townhallId}&DataInicio=${initialDate}&DataTermino=${endDate}&NomeCidade=${townhallName}&Tipo=${holidayType}&Descricao=${description}`
  });
}

export function getUniqueHoliday({ id }) {
  return api.request({
    method: "get",
    url: `/feriado/${id}`
  });
}

export function updateHoliday({
  id,
  tipo,
  estadoId,
  prefeituraId,
  descricao,
  tipoData,
  dataInicio,
  dataTermino
}) {
  return api.request({
    method: "put",
    url: `/feriado`,
    data: {
      id,
      tipo,
      estadoId,
      prefeituraId,
      descricao,
      tipoData,
      dataInicio,
      dataTermino
    }
  });
}

export function deleteHoliday({ id }) {
  return api.request({
    method: "delete",
    url: `/feriado/${id}`
  });
}

export function addRegionTime(data) {
  return api.request({
    method: "post",
    url: "/horarioregiaoprefeitura",
    data
  });
}

export function getTownHallsVacancy({ id }) {
  return api.request({
    method: "get",
    url: `/tipovagaprefeitura?PrefeituraId=${id}`
  });
}

export function addTownhallsVacancy(data) {
  return api.request({
    method: "post",
    url: "/tipovagaprefeitura",
    data
  });
}

export function updateTownhallsVacancy(data) {
  return api.request({
    method: "put",
    url: "/tipovagaprefeitura",
    data
  });
}

export function deleteTownhallsVacancy({ id }) {
  return api.request({
    method: "delete",
    url: `/tipovagaprefeitura/${id}`
  });
}

export function addRegionVancacy({ data, id }) {
  return api.request({
    method: "post",
    url: `/tipovagaregiaoprefeitura/${id}`,
    data
  });
}

export function getUniqueRegion({ id }) {
  return api.request({
    method: "get",
    url: `/regiaoprefeitura/${id}`
  });
}

export function getRegionVacancy({ id }) {
  return api.request({
    method: "get",
    url: `/tipovagaregiaoprefeitura?Include=TipoVagaPrefeitura&RegiaoPrefeituraId=${id}`
  });
}

export function getRegionTime({ id }) {
  return api.request({
    method: "get",
    url: `/horarioregiaoprefeitura?RegiaoPrefeituraId=${id}`
  });
}

export function updateRegion(data) {
  return api.request({
    method: "put",
    url: "/regiaoprefeitura",
    data
  });
}

export function deleteRegion({ id }) {
  return api.request({
    method: "delete",
    url: `/regiaoprefeitura/${id}`
  });
}

export function getEstablishmentsInRegion({
  id = "",
  regionId = "",
  cnpj = "",
  nomeFantasia = "",
  pageSize = "",
  page = ""
}) {
  return api.request({
    method: "get",
    url: `/estabelecimentoregiaoprefeitura?RegiaoPrefeituraId=${regionId}&CNPJ=${cnpj}&Id=${id}&NomeFantasia=${nomeFantasia}&PageSize=${pageSize}&Page=${page}`
  });
}

export function addEstablishmentsInRegion({ data }) {
  return api.request({
    method: "post",
    url: `/estabelecimentoregiaoprefeitura`,
    data
  });
}

export function removeEstablishmentsInRegion({ id }) {
  return api.request({
    method: "post",
    url: `/estabelecimentoregiaoprefeitura/desvincular`,
    data: id
  });
}

export function getEstablishmentsWithoutRegion({
  nomeFantasia = "",
  id = "",
  cnpj = "",
  page = "",
  pageSize = ""
}) {
  return api.request({
    method: "get",
    url: `/estabelecimento/naoinclusosregiaoprefeitura?Id=${id}&CNPJ=${cnpj}&NomeFantasia=${nomeFantasia}&PageSize=${pageSize}&Page=${page}`
  });
}

export function addPerfilAccessToUser({ userId, perfilId }) {
  return api.request({
    method: "post",
    url: "/acessousuario",
    data: {
      usuarioId: userId,
      perfilAcessoId: perfilId
    }
  });
}

export function removePerfilAccess({ id }) {
  return api.request({
    method: "delete",
    url: `/acessousuario/${id}`
  });
}

export function getProfileAccess({ userId = "" }) {
  return api.request({
    methodd: "get",
    url: `/acessousuario?UsuarioId=${userId}`
  });
}

export function getUsersByPerfil({
  townhallId = "",
  userPerfilTypeId = "",
  userIdModal = "",
  userCpfModal = "",
  userNameModal = "",
  pageSize = "",
  page = "",
  regionId = ""
}) {
  return api.request({
    method: "get",
    url: `/usuario/prefeituraperfilacesso?PrefeituraId=${townhallId}&TipoPerfilUsuario=${userPerfilTypeId}&Id=${userIdModal}&NumeroDocumento=${userCpfModal}&Nome=${userNameModal}&pageSize=${pageSize}&Page=${page}&RegiaoPrefeituraId=${regionId}`
  });
}

export function addMonitorRegion({ data }) {
  return api.request({
    method: "post",
    url: `/monitorregiaoprefeitura`,
    data
  });
}

export function getRegionMonitors({
  regionId = "",
  id = "",
  cpfFilter = "",
  nameFilter = "",
  pageSize = "",
  page = ""
}) {
  return api.request({
    method: "get",
    url: `/monitorregiaoprefeitura?RegiaoPrefeituraId=${regionId}&Id=${id}&PageSize=${pageSize}&Page=${page}&Nome=${nameFilter}&NumeroDocumento=${cpfFilter}`
  });
}

export function getRegionLogs({
  usuarioId = "",
  id = "",
  take = "",
  skip = ""
}) {
  return api.request({
    method: "get",
    url: `/logregiaoprefeitura?UsuarioId=${usuarioId}&Id=${id}&Take=${take}&Skip=${skip}`
  });
}

export function getRegionLogsReport({
  usuarioId = "",
  id = "",
  take = "",
  skip = "",
  dataInicio = "",
  dataTermino = ""
}) {
  return api.request({
    method: "get",
    url: `/logregiaoprefeitura/relatorio?UsuarioId=${usuarioId}&Id=${id}&Take=${take}&Skip=${skip}&DataInicio=${dataInicio}&DataTermino=${dataTermino}`
  });
}

export function removeRegionMonitors({ data }) {
  return api.request({
    method: "post",
    url: "/monitorregiaoprefeitura/desvincular",
    data
  });
}

export function getMasterEstablishmentCadPurchaseHistory({
  id = "",
  prefeituraId = "",
  page = "",
  pageSize = ""
}) {
  return api.request({
    method: "get",
    url: `/compracadestabelecimentomaster?&Id=${id}&PrefeituraId=${prefeituraId}&EstabelecimentoId=1&PageSize=${pageSize}&Page=${page}`
  });
}

export function getMasterEstablishmentCadSellHistory({
  prefeituraId = "",
  page = "",
  pageSize = ""
}) {
  return api.request({
    method: "get",
    url: `/prefeitura/relatoriovendacad?&PrefeituraId=${prefeituraId}&EstabelecimentoId=1&PageSize=${pageSize}&Page=${page}`
  });
}

export function getTownHallWallet({ id }) {
  return api.request({
    method: "get",
    url: `/prefeitura/carteira/${id}`
  });
}

export function verifyRemoveVacancyType(id) {
  return api.request({
    method: "get",
    url: `/tipovagaprefeitura/verificardelete/${id}`
  });
}

export function createLotCode({ prefeituraId }) {
  return api.request({
    method: "get",
    url: `/cadprefeitura/codigolote/${prefeituraId}`
  });
}

export function createTownHallCad({
  prefeituraId,
  codigoLote,
  quantidade,
  valorUnitario
}) {
  return api.request({
    method: "post",
    url: `/cadprefeitura`,
    data: {
      prefeituraId,
      codigoLote,
      quantidade,
      valorUnitario
    }
  });
}

export function getTownHallCads({
  prefeituraId,
  pageSize,
  page,
  id,
  valorUnitario,
  quantidade,
  lotCode
}) {
  return api.request({
    method: "get",
    url: `/cadprefeitura?PrefeituraId=${prefeituraId}&PageSize=${pageSize}&Page=${page}&Id=${id}&ValorUnitario=${valorUnitario}&Quantidade=${quantidade}&CodigoLote=${lotCode}`
  });
}

export function getTownHallRevenuesReport({
  prefeituraId = "",
  tipoComprador = "",
  dataInicio = "",
  dataTermino = ""
}) {
  return api.request({
    method: "get",
    url: `/prefeitura/relatoriofaturamento?PrefeituraId=${prefeituraId}&TipoComprador=${tipoComprador}&DataInicio=${dataInicio}&DataTermino=${dataTermino}`
  });
}

export function getIrregularities({
  take = "",
  skip = "",
  statusIrregularidade = "",
  prefeituraId = "",
  dataInicio = "",
  dataTermino = "",
  placaVeiculo = "",
  nome = "",
  usuarioId = ""
}) {
  return api.request({
    method: "get",
    url: `/avisoirregularidade?Take=${take}&Skip=${skip}&StatusIrregularidade=${statusIrregularidade}&PrefeituraId=${prefeituraId}&DataInicio=${dataInicio}&DataTermino=${dataTermino}&Nome=${nome}&PlacaVeiculo=${placaVeiculo}&UsuarioId=${usuarioId}`
  });
}

export function getIrregularityOperations({
  take = "",
  skip = "",
  statusIrregularidade = "",
  prefeituraId = "",
  dataInicio = "",
  dataTermino = "",
  placaVeiculo = "",
  nome = "",
  usuarioId = ""
}) {
  return api.request({
    method: "get",
    url: `/avisoirregularidade/operacao?Take=${take}&Skip=${skip}&StatusIrregularidade=${statusIrregularidade}&PrefeituraId=${prefeituraId}&DataInicio=${dataInicio}&DataTermino=${dataTermino}&Nome=${nome}&PlacaVeiculo=${placaVeiculo}&UsuarioId=${usuarioId}`
  });
}

export function getIrregularity({ id }) {
  return api.request({
    method: "get",
    url: `/avisoirregularidade/${id}`
  });
}

export function updateIrregularityStatus({
  id,
  usuarioId,
  statusIrregularidade
}) {
  return api.request({
    method: "put",
    url: `/avisoirregularidade/status`,
    data: {
      id,
      usuarioId,
      statusIrregularidade
    }
  });
}

export function getRegionVacanciesMonitoring({
  pageSize = "",
  page = "",
  prefeituraId = "",
  usuarioId
}) {
  return api.request({
    method: "get",
    url: `/regiaoprefeitura/monitoramento?PageSize=${pageSize}&Page=${page}&PrefeituraId=${prefeituraId}&UsuarioId=${usuarioId}`
  });
}

export function getRegionVacanciesReport({
  take = "",
  skip = "",
  prefeituraId = "",
  usuarioId = ""
}) {
  return api.request({
    method: "get",
    url: `/regiaoprefeitura/relatoriovagas?Take=${take}&Skip=${skip}&PrefeituraId=${prefeituraId}&UsuarioId=${usuarioId}`
  });
}

export function confirmEmail(id) {
  return api.request({
    method: "get",
    url: `/usuario/confirmaremail/${id}`
  });
}

export function addTownHallActivationLimit(data) {
  return api.request({
    method: "POST",
    url: "/limiteativacaoprefeitura",
    data
  });
}

export function getTownHallActivationLimit({ townHallId }) {
  return api.request({
    method: "GET",
    url: `/limiteativacaoprefeitura?PrefeituraId=${townHallId}`
  });
}

export function deleteTownHallActivationLimit(activationLimitId) {
  return api.request({
    method: "DELETE",
    url: `/limiteativacaoprefeitura/${activationLimitId}`
  });
}

export function updateTownHallActivationLimit(data) {
  return api.request({
    method: "PUT",
    url: `/limiteativacaoprefeitura`,
    data
  });
}

export function getReportDashboardHistory({ townHallId }) {
  return api.request({
    method: "GET",
    url: `/dashboard?prefeituraId=${townHallId}`
  });
}

export function getReportDashboardBuyUser({
  townHallId,
  initialDate,
  endDate,
  interval
}) {
  return api.request({
    method: "GET",
    url: `/dashboard/compracadusuario?prefeituraId=${townHallId}&dataInicio=${initialDate}&dataTermino=${endDate}&intervaloTempo=${interval}`
  });
}

export function getReportDashboardBuyEstablishment({
  townHallId,
  initialDate,
  endDate,
  interval
}) {
  return api.request({
    method: "GET",
    url: `/dashboard/compracadestabelecimento?prefeituraId=${townHallId}&dataInicio=${initialDate}&dataTermino=${endDate}&intervaloTempo=${interval}`
  });
}

export function getReportDashboardActivationUser({
  townHallId,
  initialDate,
  endDate,
  interval
}) {
  return api.request({
    method: "GET",
    url: `/dashboard/ativacaocadusuario?prefeituraId=${townHallId}&dataInicio=${initialDate}&dataTermino=${endDate}&intervaloTempo=${interval}`
  });
}

export function getReportDashboardActivationEstablishment({
  townHallId,
  initialDate,
  endDate,
  interval
}) {
  return api.request({
    method: "GET",
    url: `/dashboard/ativacaocadestabelecimento?prefeituraId=${townHallId}&dataInicio=${initialDate}&dataTermino=${endDate}&intervaloTempo=${interval}`
  });
}

export function getCancelList({ pageSize, page, status }) {
  const townHallId = "";
  const order = status === 1 ? "id ASC" : "id DESC";

  return api.request({
    method: "GET",
    url: `/cancelamentoassinaturausuario?prefeituraId=${townHallId}&PageSize=${pageSize}&Page=${page}&SituacaoContaUsuario=${status}&OrderBy=${order}`
  });
}

export function getCancelOrder({ id }) {
  return api.request({
    method: "GET",
    url: `/cancelamentoassinaturausuario/${id}`
  });
}

export function cancelWalletUser({ id, transferNumber }) {
  return api.request({
    method: "POST",
    url: `/cancelamentoassinaturausuario/${id}`,
    data: {
      id,
      numeroTransacao: transferNumber
    }
  });
}

export function getNotifications({
  page = "",
  pageSize = "",
  id = "",
  date = "",
  sender = "",
  title = "",
  message = ""
}) {
  return api.request({
    method: "GET",
    url: `/pushnotification?Id=${id}&Page=${page}&PageSize=${pageSize}&Assunto=${title}&Mensagem=${message}&UsuarioEmail=${sender}&DataSolicitacaoInicio=${date}`
  });
}

export function createNotification({
  title = "",
  message = "",
  townHallIds = "",
  sendStatus = "",
  allTownHalls = ""
}) {
  return api.request({
    method: "POST",
    url: "/pushnotification",
    data: {
      assunto: title,
      mensagem: message,
      prefeituraIds: townHallIds,
      statusEnvio: sendStatus,
      enviarTodasPrefeituras: allTownHalls
    }
  });
}

export function geocoding(address) {
  return api.request({
    method: "GET",
    url: `/geolocalizacao/consultarendereco?endereco=${address}`
  });
}

export function getUserExtract({
  page = 1,
  pageSize = 10,
  id = "",
  compraAtivacao = 1,
  townHallId
}) {
  function getPage() {
    return (page - 1) * pageSize;
  }
  return api.get(
    `/HistoricoCompraAtivacaoMaster?UsuarioId=${id}&CompraAtivacao=${compraAtivacao}&Skip=${getPage()}&Take=${pageSize}&PrefeituraId=${townHallId}`
  );
}

export default api;

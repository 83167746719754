export const Types = {
  GET_REGIONS: "GET_REGIONS",
  GET_REGIONS_SUCCESS: "GET_REGIONS_SUCCESS",
  GET_REGIONS_ERROR: "GET_REGIONS_ERROR",

  GET_REGION_ESTABLISHMENTS: "GET_REGION_ESTABLISHMENTS",
  GET_REGION_ESTABLISHMENTS_SUCCESS: "GET_REGION_ESTABLISHMENTS_SUCCESS",
  GET_REGION_ESTABLISHMENTS_ERROR: "GET_REGION_ESTABLISHMENTS_ERROR",

  GET_REGION_MONITORS: "GET_REGION_MONITORS",
  GET_REGION_MONITORS_SUCCESS: "GET_REGION_MONITORS_SUCCESS",
  GET_REGION_MONITORS_ERRORS: "GET_REGION_MONITORS_ERRORS",

  GET_REGION_LOGS: "GET_REGION_LOGS",
  GET_REGION_LOGS_SUCCESS: "GET_REGION_LOGS_SUCCESS",
  GET_REGION_LOGS_ERRORS: "GET_REGION_LOGS_ERRORS",

  GET_REGION_LOGS_REPORT: "GET_REGION_LOGS_REPORT",
  GET_REGION_LOGS_REPORT_SUCCESS: "GET_REGION_LOGS_REPORT_SUCCESS",
  GET_REGION_LOGS_REPORT_ERRORS: "GET_REGION_LOGS_REPORT_ERRORS",

  GET_REGION_VACANCIES_MONITORING: "GET_REGION_VACANCIES_MONITORING",
  GET_REGION_VACANCIES_MONITORING_SUCCESS:
    "GET_REGION_VACANCIES_MONITORING_SUCCESS",
  GET_REGION_VACANCIES_MONITORING_ERROR:
    "GET_REGION_VACANCIES_MONITORING_ERROR",

  GET_REGION_VACANCIES_REPORT: "GET_REGION_VACANCIES_REPORT",
  GET_REGION_VACANCIES_REPORT_SUCCESS: "GET_REGION_VACANCIES_REPORT_SUCCESS",
  GET_REGION_VACANCIES_REPORT_ERROR: "GET_REGION_VACANCIES_REPORT_ERROR",

  ADD_REGION: "ADD_REGION",
  ADD_REGION_SUCCESS: "ADD_REGION_SUCCESS",
  ADD_REGION_ERROR: "ADD_REGION_ERROR",

  ADD_REGION_ESTABLISHMENT: "ADD_REGION_ESTABLISHMENT",
  ADD_REGION_ESTABLISHMENT_SUCCESS: "ADD_REGION_ESTABLISHMENT_SUCCESS",
  ADD_REGION_ESTABLISHMENT_ERROR: "ADD_REGION_ESTABLISHMENT_ERROR",

  ADD_REGION_MONITOR: "ADD_REGION_MONITOR",
  ADD_REGION_MONITOR_SUCCESS: "ADD_REGION_MONITOR_SUCCESS",
  ADD_REGION_MONITOR_ERROR: "ADD_REGION_MONITOR_ERROR",

  UPDATE_REGION: "UPDATE_REGION",
  UPDATE_REGION_SUCCESS: "UPDATE_REGION_SUCCESS",
  UPDATE_REGION_ERROR: "UPDATE_REGION_ERROR",

  DELETE_REGION: "DELETE_REGION",
  DELETE_REGION_SUCCESS: "DELETE_REGION_SUCCESS",
  DELETE_REGION_ERROR: "DELETE_REGION_ERROR",

  REMOVE_REGION_ESTABLISHMENT: "REMOVE_REGION_ESTABLISHMENT",
  REMOVE_REGION_ESTABLISHMENT_SUCCESS: "REMOVE_REGION_ESTABLISHMENT_SUCCESS",
  REMOVE_REGION_ESTABLISHMENT_ERROR: "REMOVE_REGION_ESTABLISHMENT_ERROR",

  REMOVE_REGION_MONITOR: "REMOVE_REGION_MONITOR",
  REMOVE_REGION_MONITOR_SUCCESS: "REMOVE_REGION_MONITOR_SUCCESS",
  REMOVE_REGION_MONITOR_ERROR: "REMOVE_REGION_MONITOR_ERROR",

  ADD_WORKING_TIME_REGION: "ADD_WORKING_TIME_REGION",
  ADD_PARKING_CONFIG_REGION: "ADD_PARKING_CONFIG_REGION",
  UPDATE_WORKING_TIME_REGION: "UPDATE_WORKING_TIME_REGION",
  UPDATE_PARKING_CONFIG_REGION: "UPDATE_PARKING_CONFIG_REGION",
  REMOVE_WORKING_TIME_REGION: "REMOVE_WORKING_TIME_REGION",
  REMOVE_PARKING_CONFIG_REGION: "REEMOVE_PARKING_CONFIG_REGION",

  RESET_REGION_LIST: "RESET_REGION_LIST",

  RESET_REGION_CONFIG: "RESET_REGION_CONFIG"
};

const INIT_STATE = {
  list: [],
  workingTime: [],
  parkingConfig: [],
  establishmentList: [],
  vacanciesMonitoringCount: 0,
  vacanciesMonitoring: [],
  vacanciesReport: [],
  vacanciesReportCount: 0,
  monitorList: [],
  monitorCount: 0,
  regionLogs: [],
  regionLogsCount: 0,
  regionLogsReport: [],
  regionLogsReportCount: 0,
  count: 0,
  errors: [],
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.GET_REGIONS:
      return {
        ...state,
        loading: true,
        error: ""
      };

    case Types.GET_REGIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        count: action.payload.count,
        list: action.payload.list,
        errors: ""
      };

    case Types.GET_REGIONS_ERROR:
      return {
        ...state,
        loading: false,
        list: [],
        errors: action.payload.errors
      };

    case Types.ADD_WORKING_TIME_REGION: {
      const newArray = [...state.workingTime];
      const { timeObj } = action.payload;
      if (newArray.length > 0) {
        const lastObj = newArray[newArray.length - 1];
        const lastId = lastObj.tempId.replace(/\D/g, "");
        timeObj.tempId = `HF${Number(lastId) + 1}`;
      } else {
        timeObj.tempId = "HF0";
      }

      newArray.push(timeObj);
      return {
        ...state,
        workingTime: newArray
      };
    }

    case Types.ADD_REGION:
      return {
        ...state,
        loading: true
      };

    case Types.ADD_REGION_SUCCESS: {
      // eslint-disable-next-line prefer-const
      let newArray = [...state.list];

      if (state.list.length > 0) {
        const { prefeituraId } = state.list[0];

        if (prefeituraId === action.payload.region.prefeituraId) {
          newArray.push(action.payload.region);
        }
      }
      return {
        ...state,
        loading: false,
        list: newArray
      };
    }

    case Types.ADD_REGION_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      };

    case Types.REMOVE_WORKING_TIME_REGION: {
      let newArray = [...state.workingTime];
      newArray = newArray.filter(item => item.tempId !== action.payload.tempId);
      return {
        ...state,
        workingTime: newArray
      };
    }

    case Types.ADD_PARKING_CONFIG_REGION: {
      const newArray = [...state.parkingConfig];
      const { vacancyObj } = action.payload;
      if (newArray.length > 0) {
        const lastObj = newArray[newArray.length - 1];
        const lastId = lastObj.tempId.replace(/\D/g, "");
        vacancyObj.tempId = `TP${Number(lastId) + 1}`;
      } else {
        vacancyObj.tempId = "TP0";
      }

      newArray.push(vacancyObj);

      return {
        ...state,
        parkingConfig: newArray
      };
    }

    case Types.REMOVE_PARKING_CONFIG_REGION: {
      let newArray = [...state.parkingConfig];
      newArray = newArray.filter(item => item.tempId !== action.payload.tempId);
      return {
        ...state,
        parkingConfig: newArray
      };
    }

    case Types.UPDATE_WORKING_TIME_REGION: {
      const { workingTime } = state;
      const newTime = action.payload.timeObj;

      const newArray = workingTime.map(obj => {
        if (obj.tempId === newTime.tempId) {
          newTime.updated = true;
          return newTime;
        }
        return obj;
      });
      return {
        ...state,
        workingTime: newArray
      };
    }

    case Types.UPDATE_PARKING_CONFIG_REGION: {
      const { parkingConfig } = state;
      const newConfig = action.payload.vacancyObj;

      const newArray = parkingConfig.map(obj => {
        if (obj.tempId === newConfig.tempId) {
          return newConfig;
        }

        return obj;
      });
      return {
        ...state,
        parkingConfig: newArray
      };
    }

    case Types.UPDATE_REGION: {
      return {
        ...state,
        loading: true
      };
    }

    case Types.UPDATE_REGION_SUCCESS: {
      const { region } = action.payload;
      const newArray = state.list.map(item => {
        if (item.id === region.id) {
          return region;
        }
        return item;
      });

      return {
        ...state,
        loading: false,
        list: newArray
      };
    }

    case Types.UPDATE_REGION_ERROR: {
      return {
        ...state,
        loading: false
      };
    }

    case Types.DELETE_REGION:
      return {
        ...state,
        loading: true
      };

    case Types.DELETE_REGION_SUCCESS: {
      const { id } = action.payload;
      const newArray = state.list.filter(r => r.id !== id);

      return {
        ...state,
        list: newArray,
        loading: false
      };
    }

    case Types.DELETE_REGION_ERROR:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      };

    case Types.GET_REGION_ESTABLISHMENTS:
      return {
        ...state,
        loading: true
      };

    case Types.GET_REGION_ESTABLISHMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        establishmentList: action.payload.establishmentList,
        establishmentCount: action.payload.count
      };

    case Types.GET_REGION_ESTABLISHMENTS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      };

    case Types.REMOVE_REGION_ESTABLISHMENT:
      return {
        ...state,
        loading: true
      };

    case Types.REMOVE_REGION_ESTABLISHMENT_SUCCESS: {
      const { id } = action.payload;
      let newArray = state.establishmentList;
      newArray = newArray.filter(item => item.id !== id);

      return {
        ...state,
        loading: false,
        establishmentList: newArray
      };
    }

    case Types.REMOVE_REGION_ESTABLISHMENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      };

    case Types.ADD_REGION_ESTABLISHMENT:
      return {
        ...state,
        loading: true
      };

    case Types.ADD_REGION_ESTABLISHMENT_SUCCESS: {
      const { newEstablishmentsList } = action.payload;
      const newCount = state.establishmentCount + newEstablishmentsList.length;
      const newArray = [...state.establishmentList];
      Array.prototype.push.apply(newArray, newEstablishmentsList);

      return {
        ...state,
        loading: false,
        establishmentList: newArray,
        establishmentCount: newCount
      };
    }

    case Types.ADD_REGION_ESTABLISHMENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      };

    case Types.GET_REGION_MONITORS:
      return {
        ...state,
        loading: true
      };

    case Types.GET_REGION_MONITORS_SUCCESS:
      return {
        ...state,
        loading: false,
        monitorList: action.payload.monitorsList,
        monitorCount: action.payload.monitorCount
      };

    case Types.GET_REGION_MONITORS_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      };

    case Types.GET_REGION_LOGS:
      return {
        ...state,
        loading: true
      };

    case Types.GET_REGION_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        regionLogs: action.payload.regionLogs,
        regionLogsCount: action.payload.regionLogsCount
      };

    case Types.GET_REGION_LOGS_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      };

    case Types.GET_REGION_LOGS_REPORT:
      return {
        ...state,
        loading: true
      };

    case Types.GET_REGION_LOGS_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        regionLogsReport: action.payload.regionLogsReport,
        regionLogsReportCount: action.payload.regionLogsReportCount
      };

    case Types.GET_REGION_LOGS_REPORT_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      };

    case Types.ADD_REGION_MONITOR:
      return {
        ...state,
        loading: true
      };

    case Types.ADD_REGION_MONITOR_SUCCESS: {
      const { newMonitorsList } = action.payload;
      const newCount = state.monitorCount + newMonitorsList.length;
      const newArray = [...state.monitorList];
      Array.prototype.push.apply(newArray, newMonitorsList);

      return {
        ...state,
        loading: false,
        monitorList: newArray,
        monitorCount: newCount
      };
    }

    case Types.ADD_REGION_MONITOR_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      };

    case Types.REMOVE_REGION_MONITOR:
      return {
        ...state,
        loading: true
      };

    case Types.REMOVE_REGION_MONITOR_SUCCESS: {
      const newArray = state.monitorList.filter(
        item => item.id !== action.payload.id
      );

      return {
        ...state,
        loading: false,
        monitorList: newArray
      };
    }

    case Types.REMOVE_REGION_MONITOR_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      };

    case Types.GET_REGION_VACANCIES_MONITORING:
      return {
        ...state,
        loading: true
      };

    case Types.GET_REGION_VACANCIES_MONITORING_SUCCESS:
      return {
        ...state,
        loading: false,
        vacanciesMonitoring: action.payload.vacanciesMonitoring,
        vacanciesMonitoringCount: action.payload.vacanciesMonitoringCount
      };

    case Types.GET_REGION_VACANCIES_MONITORING_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      };

    case Types.GET_REGION_VACANCIES_REPORT:
      return {
        ...state,
        loading: true
      };

    case Types.GET_REGION_VACANCIES_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        vacanciesReport: action.payload.vacanciesReport,
        vacanciesReportCount: action.payload.vacanciesReportCount
      };

    case Types.GET_REGION_VACANCIES_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      };

    case Types.RESET_REGION_LIST:
      return {
        ...state,
        list: [],
        count: 0
      };

    case Types.RESET_REGION_CONFIG:
      return {
        ...state,
        workingTime: [],
        parkingConfig: []
      };

    default:
      return { ...state };
  }
};

export const getAllRegions = ({
  id = "",
  prefeituraId = "",
  estadoId = "",
  page = "",
  pageSize = ""
}) => ({
  type: Types.GET_REGIONS,
  payload: {
    id,
    prefeituraId,
    estadoId,
    page,
    pageSize
  }
});

export const addWorkingTime = timeObj => ({
  type: Types.ADD_WORKING_TIME_REGION,
  payload: {
    timeObj
  }
});

export const updateWorkingTime = timeObj => ({
  type: Types.UPDATE_WORKING_TIME_REGION,
  payload: {
    timeObj
  }
});

export const addParkingConfig = vacancyObj => ({
  type: Types.ADD_PARKING_CONFIG_REGION,
  payload: {
    vacancyObj
  }
});

export const updateParkingConfig = vacancyObj => ({
  type: Types.UPDATE_PARKING_CONFIG_REGION,
  payload: {
    vacancyObj
  }
});

export const addRegion = ({
  nome,
  prefeituraId,
  estadoId,
  coordenadas,
  workingTime,
  parkingConfig,
  redirectToBack,
  tolerancia,
  ordemPrioridade
}) => ({
  type: Types.ADD_REGION,
  payload: {
    nome,
    prefeituraId,
    estadoId,
    coordenadas,
    workingTime,
    parkingConfig,
    redirectToBack,
    tolerancia,
    ordemPrioridade
  }
});

export const removeRegionTime = ({ tempId }) => ({
  type: Types.REMOVE_WORKING_TIME_REGION,
  payload: {
    tempId
  }
});

export const removeParkingConfig = ({ tempId }) => ({
  type: Types.REMOVE_PARKING_CONFIG_REGION,
  payload: {
    tempId
  }
});

export const updateRegion = ({
  nome,
  id,
  tolerancia,
  workingTime,
  parkingConfig,
  redirectToBack,
  ordemPrioridade
}) => ({
  type: Types.UPDATE_REGION,
  payload: {
    nome,
    id,
    workingTime,
    parkingConfig,
    redirectToBack,
    tolerancia,
    ordemPrioridade
  }
});

export const deleteRegion = id => ({
  type: Types.DELETE_REGION,
  payload: {
    id
  }
});

export const getRegionEstablishments = ({
  id,
  regionId,
  cnpj,
  nomeFantasia,
  pageSize,
  page
}) => ({
  type: Types.GET_REGION_ESTABLISHMENTS,
  payload: {
    regionId,
    id,
    cnpj,
    nomeFantasia,
    pageSize,
    page
  }
});

export const removeRegionEstablishment = id => ({
  type: Types.REMOVE_REGION_ESTABLISHMENT,
  payload: {
    id
  }
});

export const addRegionEstablishments = data => ({
  type: Types.ADD_REGION_ESTABLISHMENT,
  payload: {
    data
  }
});

export const addRegionMonitor = data => ({
  type: Types.ADD_REGION_MONITOR,
  payload: {
    data
  }
});

export const getRegionMonitors = ({
  regionId,
  id,
  cpfFilter,
  nameFilter,
  pageSize,
  page
}) => ({
  type: Types.GET_REGION_MONITORS,
  payload: {
    regionId,
    id,
    cpfFilter,
    nameFilter,
    pageSize,
    page
  }
});

export const getRegionLogs = ({ id, usuarioId, take, skip }) => ({
  type: Types.GET_REGION_LOGS,
  payload: {
    id,
    usuarioId,
    take,
    skip
  }
});

export const getRegionLogsReport = ({
  id,
  usuarioId,
  take,
  skip,
  dataInicio,
  dataTermino
}) => ({
  type: Types.GET_REGION_LOGS_REPORT,
  payload: {
    id,
    usuarioId,
    take,
    skip,
    dataInicio,
    dataTermino
  }
});

export const removeRegionMonitors = data => ({
  type: Types.REMOVE_REGION_MONITOR,
  payload: {
    data
  }
});

export const getRegionVacanciesMonitoring = ({
  usuarioId,
  prefeituraId,
  pageSize,
  page
}) => ({
  type: Types.GET_REGION_VACANCIES_MONITORING,
  payload: {
    usuarioId,
    prefeituraId,
    pageSize,
    page
  }
});

export const getRegionVacanciesReport = ({
  usuarioId,
  prefeituraId,
  take,
  skip
}) => ({
  type: Types.GET_REGION_VACANCIES_REPORT,
  payload: {
    usuarioId,
    prefeituraId,
    take,
    skip
  }
});

export const resetRegionList = () => ({
  type: Types.RESET_REGION_LIST
});

export const resetRegionConfig = () => ({
  type: Types.RESET_REGION_CONFIG
});

/* eslint-disable no-param-reassign */
import { all, call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { Types } from "./index";
import { Alert } from "../../helpers/Alert";
import FormatErrors from "../../helpers/Errors";
import {
  getRegions,
  addRegion,
  addRegionTime,
  addRegionVancacy,
  updateRegion,
  deleteRegion,
  getEstablishmentsInRegion,
  addEstablishmentsInRegion,
  removeEstablishmentsInRegion,
  addMonitorRegion,
  getRegionMonitors,
  removeRegionMonitors,
  getRegionVacanciesMonitoring,
  getRegionVacanciesReport,
  getRegionLogs,
  getRegionLogsReport
} from "../../services/http/index";

function* getAllRegionsAsync({ payload }) {
  try {
    const response = yield call(getRegions, payload);
    yield put({
      type: Types.GET_REGIONS_SUCCESS,
      payload: {
        list: response.data.data,
        count: response.data.count
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_REGIONS_ERROR,
      payload: {
        // errors: error.response.data.errors
      }
    });
  }
}

function* addRegionAsync({ payload }) {
  try {
    const regionResponse = yield call(addRegion, payload);

    const { workingTime, parkingConfig, redirectToBack } = payload;
    const { id } = regionResponse.data.data;

    const workingTimeWithoutId = workingTime.map(obj => {
      delete obj.tempId;
      return {
        ...obj,
        regiaoPrefeituraId: id,
        horasRegiaoPrefeitura: obj.horasRegiaoPrefeitura.map(e => ({
          horaInicio: e.horaInicio,
          horaTermino: e.horaTermino
        }))
      };
    });
    console.log(parkingConfig, "asdasdad");
    const parkingConfigWithoutId = parkingConfig.map(obj => ({
      tipoVagaPrefeituraId: obj.vacancyId,
      regiaoPrefeituraId: id,
      duracao: obj.duration,
      limite: obj.limiActivationCAD,
      // tolerancia: obj.tolerance,
      quantidadeVaga: obj.quantity,
      status: obj.status
    }));

    if (workingTimeWithoutId.length > 0) {
      yield call(addRegionTime, workingTimeWithoutId);
    }

    if (parkingConfigWithoutId.length > 0) {
      yield call(addRegionVancacy, { data: parkingConfigWithoutId, id });
    }

    Alert({
      title: "Região criada com sucesso!",
      type: "success"
    }).then(e => redirectToBack());

    yield put({
      type: Types.ADD_REGION_SUCCESS,
      payload: {
        region: regionResponse.data.data
      }
    });
  } catch (error) {
    yield put({
      type: Types.ADD_REGION_ERROR,
      payload: {
        region: error.response.data.errors
      }
    });

    Alert({
      title: FormatErrors(error),
      type: "error"
    });
  }
}

function* updateRegionAsync({ payload }) {
  try {
    const {
      nome,
      id,
      parkingConfig,
      tolerancia,
      workingTime,
      ordemPrioridade,
      redirectToBack
    } = payload;
    console.log(parkingConfig, "asdasdad");
    const parkingConfigWithoutId = parkingConfig.map(obj => {
      const formattedObj = {
        tipoVagaPrefeituraId: Number(obj.vacancyId),
        regiaoPrefeituraId: Number(id),
        quantidadeVaga: Number(obj.quantity)
      };
      if (obj.id) {
        formattedObj.id = Number(obj.id);
      }
      return formattedObj;
    });

    const workingTimeWithoutId = workingTime.map(obj => {
      // eslint-disable-next-line no-param-reassign
      delete obj.tempId;

      if (obj.id) {
        return {
          ...obj,
          id: obj.id,
          regiaoPrefeituraId: id,
          horasRegiaoPrefeitura: obj.horasRegiaoPrefeitura.map(e => {
            if (e.horarioRegiaoPrefeituraId) {
              return e;
            }

            return {
              horaInicio: e.horaInicio,
              horaTermino: e.horaTermino
            };
          })
        };
      }
      return {
        ...obj,
        regiaoPrefeituraId: id,
        horasRegiaoPrefeitura: obj.horasRegiaoPrefeitura.map(e => {
          return {
            horaInicio: e.horaInicio,
            horaTermino: e.horaTermino
          };
        })
      };
    });

    const regionResponse = yield call(updateRegion, {
      id,
      nome,
      tolerancia,
      ordemPrioridade
    });

    if (workingTimeWithoutId.length > 0) {
      yield call(addRegionTime, workingTimeWithoutId);
    }

    if (parkingConfigWithoutId.length > 0) {
      yield call(addRegionVancacy, { data: parkingConfigWithoutId, id });
    }

    Alert({
      title: "Região atualizada com sucesso!",
      type: "success"
    }).then(e => redirectToBack());

    yield put({
      type: Types.UPDATE_REGION_SUCCESS,
      payload: {
        region: regionResponse.data.data
      }
    });
  } catch (error) {
    Alert({
      title: FormatErrors(error),
      type: "error"
    });

    yield put({
      type: Types.UPDATE_REGION_ERROR
    });
  }
}

function* deleteRegionAsync({ payload }) {
  try {
    yield call(deleteRegion, payload);

    yield put({
      type: Types.DELETE_REGION_SUCCESS,
      payload: {
        id: payload.id
      }
    });

    Alert({
      title: "Região removida com sucesso!",
      type: "success"
    });
  } catch (err) {
    yield put({
      type: Types.DELETE_REGION_ERROR,
      payload: {
        err
      }
    });
    Alert({
      title: FormatErrors(err),
      type: "error"
    });
  }
}

function* getEstablishmentsInRegionAsync({ payload }) {
  try {
    const response = yield call(getEstablishmentsInRegion, payload);

    yield put({
      type: Types.GET_REGION_ESTABLISHMENTS_SUCCESS,
      payload: {
        establishmentList: response.data.data,
        count: response.data.count
      }
    });
  } catch (err) {
    Alert({
      title: FormatErrors(err),
      type: "error"
    });
  }
}

function* removeRegionEstablishmentsAsync({ payload }) {
  try {
    yield call(removeEstablishmentsInRegion, payload);

    yield put({
      type: Types.REMOVE_REGION_ESTABLISHMENT_SUCCESS,
      payload: {
        id: payload.id[0]
      }
    });
    Alert({
      title: "O estabelecimento foi desvinculado da região!",
      type: "success"
    });
  } catch (err) {
    Alert({
      title: FormatErrors(err),
      type: "error"
    });
  }
}

function* addEstablishmentsInRegionAsync({ payload }) {
  try {
    const response = yield call(addEstablishmentsInRegion, payload);

    let newEstablishmentsList = [];

    response.data.data.map(item => {
      newEstablishmentsList = [...newEstablishmentsList, item];
    });

    yield put({
      type: Types.ADD_REGION_ESTABLISHMENT_SUCCESS,
      payload: {
        newEstablishmentsList
      }
    });

    Alert({
      title:
        newEstablishmentsList.length === 1
          ? "O estabelecimento foi vinculado a região"
          : "Os estabelecimentos foram vinculados a região",
      type: "success"
    });
  } catch (err) {
    yield put({
      type: Types.ADD_REGION_ESTABLISHMENT_ERROR,
      payload: {
        errors: err
      }
    });
    Alert({
      title: FormatErrors(err),
      type: "error"
    });
  }
}

function* addMonitorRegionAsync({ payload }) {
  try {
    const response = yield call(addMonitorRegion, payload);

    let newMonitorsList = [];

    response.data.data.map(item => {
      newMonitorsList = [...newMonitorsList, item];
    });

    yield put({
      type: Types.ADD_REGION_MONITOR_SUCCESS,
      payload: {
        newMonitorsList
      }
    });

    Alert({
      title:
        newMonitorsList.length === 1
          ? "O monitor foi vinculado a região"
          : "Os monitores foram vinculados a região",
      type: "success"
    });
  } catch (err) {
    Alert({
      title: FormatErrors(err),
      type: "error"
    });
  }
}

function* getMonitorsRegionAsync({ payload }) {
  try {
    const response = yield call(getRegionMonitors, payload);

    yield put({
      type: Types.GET_REGION_MONITORS_SUCCESS,
      payload: {
        monitorsList: response.data.data,
        monitorCount: response.data.count
      }
    });
  } catch (err) {
    Alert({
      title: FormatErrors(err),
      type: "error"
    });
  }
}

function* getRegionLogsAsync({ payload }) {
  try {
    const response = yield call(getRegionLogs, payload);

    yield put({
      type: Types.GET_REGION_LOGS_SUCCESS,
      payload: {
        regionLogs: response.data.data,
        regionLogsCount: response.data.count
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_REGION_LOGS_ERRORS,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* getRegionLogsReportAsync({ payload }) {
  try {
    const response = yield call(getRegionLogsReport, payload);

    yield put({
      type: Types.GET_REGION_LOGS_REPORT_SUCCESS,
      payload: {
        regionLogsReport: response.data.data,
        regionLogsReportCount: response.data.count
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_REGION_LOGS_REPORT_ERRORS,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* removeRegionMonitorsAsync({ payload }) {
  try {
    yield call(removeRegionMonitors, payload);

    yield all(
      payload.data.map(itemId =>
        put({
          type: Types.REMOVE_REGION_MONITOR_SUCCESS,
          payload: {
            id: itemId
          }
        })
      )
    );
    Alert({
      title: "Monitor desvinculado da região!",
      type: "success"
    });
  } catch (err) {
    Alert({
      title: FormatErrors(err),
      type: "error"
    });
  }
}

function* getRegionVacanciesMonitoringAsync({ payload }) {
  try {
    const response = yield call(getRegionVacanciesMonitoring, payload);

    yield put({
      type: Types.GET_REGION_VACANCIES_MONITORING_SUCCESS,
      payload: {
        vacanciesMonitoring: response.data.data,
        vacanciesMonitoringCount: response.data.count
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_REGION_VACANCIES_MONITORING_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* getRegionVacanciesReportAsync({ payload }) {
  try {
    const response = yield call(getRegionVacanciesReport, payload);

    yield put({
      type: Types.GET_REGION_VACANCIES_REPORT_SUCCESS,
      payload: {
        vacanciesReport: response.data.data,
        vacanciesReportCount: response.data.count
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_REGION_VACANCIES_REPORT_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(Types.GET_REGIONS, getAllRegionsAsync),
    yield takeEvery(Types.ADD_REGION, addRegionAsync),
    yield takeEvery(Types.UPDATE_REGION, updateRegionAsync),
    yield takeEvery(Types.DELETE_REGION, deleteRegionAsync),
    yield takeEvery(
      Types.GET_REGION_ESTABLISHMENTS,
      getEstablishmentsInRegionAsync
    ),
    yield takeEvery(
      Types.REMOVE_REGION_ESTABLISHMENT,
      removeRegionEstablishmentsAsync
    ),
    yield takeEvery(
      Types.ADD_REGION_ESTABLISHMENT,
      addEstablishmentsInRegionAsync
    ),
    yield takeEvery(Types.ADD_REGION_MONITOR, addMonitorRegionAsync),
    takeEvery(Types.GET_REGION_MONITORS, getMonitorsRegionAsync),
    yield takeLatest(Types.REMOVE_REGION_MONITOR, removeRegionMonitorsAsync),
    yield takeLatest(
      Types.GET_REGION_VACANCIES_MONITORING,
      getRegionVacanciesMonitoringAsync
    ),
    yield takeLatest(
      Types.GET_REGION_VACANCIES_REPORT,
      getRegionVacanciesReportAsync
    ),
    yield takeLatest(Types.GET_REGION_LOGS, getRegionLogsAsync),
    yield takeLatest(Types.GET_REGION_LOGS_REPORT, getRegionLogsReportAsync)
  ]);
}

import { all, call, put, takeLatest } from "redux-saga/effects";
import { Types, actions } from "./index";
import { getClients } from "../../services/http";

function* getClientsAsync(obj) {
  // console.log(obj.payload, 'payload')
  try {
    const response = yield call(getClients, obj.payload);
    const clientList = [];
    for (const data of response.data.data) {
      clientList.push(data.usuario);
    }
    yield put(
      actions.GetClientsSuccess({
        clientList,
        clientCount: response.data.count
      })
    );
  } catch (error) {
    /* console.log(error);
    yield put(actions.GetClientsFailure(error?.errors)); */
    yield put(
      actions.GetClientsSuccess({
        clientList: [],
        clientCount: 0
      })
    );
  }
}

export default function* rootSaga() {
  yield all([yield takeLatest(Types.GET_CLIENTS_REQUEST, getClientsAsync)]);
}

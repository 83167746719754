export const Types = {
  ADD_HOLIDAY: "ADD_HOLIDAY",
  ADD_HOLIDAY_SUCCESS: "ADD_HOLIDAY_SUCCESS",
  ADD_HOLIDAY_ERROR: "ADD_HOLIDAY_ERROR",

  GET_HOLIDAYS: "GET_HOLIDAYS",
  GET_HOLIDAYS_SUCCESS: "GET_HOLIDAYS_SUCCESS",
  GET_HOLIDAYS_ERROR: "GET_HOLIDAYS_ERROR",

  GET_UNIQUE_HOLIDAY: "GET_UNIQUE_HOLIDAY",
  GET_UNIQUE_HOLIDAY_SUCCESS: "GET_UNIQUE_HOLIDAY_SUCCESS",
  GET_UNIQUE_HOLIDAY_ERROR: "GET_UNIQUE_HOLIDAY_ERROR",

  UPDATE_HOLIDAY: "UPDATE_HOLIDAY",
  UPDATE_HOLIDAY_SUCCESS: "UPDATE_HOLIDAY_SUCCESS",
  UPDATE_HOLIDAY_ERROR: "UPDATE_HOLIDAY_ERROR",

  DELETE_HOLIDAY: "DELETE_HOLIDAY",
  DELETE_HOLIDAY_SUCCESS: "DELETE_HOLIDAY_SUCCESS",
  DELETE_HOLIDAY_ERROR: "DELETE_HOLIDAY_ERROR",

  RESET_HOLIDAY_STATUS: "RESET_HOLIDAY_STATUS"
};

const INIT_STATE = {
  holidayList: [],
  totalHolidaysCount: "",
  uniqueHoliday: null,
  loading: false,
  error: null,

  getHolidaysSuccess: false,
  getUniqueHolidaySuccess: false,
  createHolidaySuccess: false,
  updateHolidaySuccess: false,
  deleteHolidaySuccess: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.ADD_HOLIDAY:
      return {
        ...state,
        loading: true,
        createHolidaySuccess: false
      };

    case Types.ADD_HOLIDAY_SUCCESS: {
      const newArray = state.holidayList;
      newArray.push(action.payload.holiday);
      return {
        ...state,
        loading: false,
        holidayList: newArray,
        createHolidaySuccess: true
      };
    }

    case Types.ADD_HOLIDAY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        createHolidaySuccess: false
      };
    }

    case Types.GET_HOLIDAYS:
      return {
        ...state,
        loading: true,
        getHolidaysSuccess: false
      };

    case Types.GET_HOLIDAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        holidayList: action.payload.holidayList,
        totalHolidaysCount: action.payload.count,
        getHolidaysSuccess: true
      };

    case Types.GET_HOLIDAYS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        getHolidaysSuccess: false
      };

    case Types.GET_UNIQUE_HOLIDAY:
      return {
        ...state,
        loading: true,
        getUniqueHolidaySuccess: false
      };

    case Types.GET_UNIQUE_HOLIDAY_SUCCESS:
      return {
        ...state,
        loading: false,
        uniqueHoliday: action.payload.uniqueHoliday,
        getUniqueHolidaySuccess: true
      };

    case Types.GET_UNIQUE_HOLIDAY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        getUniqueHolidaySuccess: false
      };

    case Types.UPDATE_HOLIDAY:
      return {
        ...state,
        loading: true,
        updateHolidaySuccess: false
      };

    case Types.UPDATE_HOLIDAY_SUCCESS: {
      const { holiday } = action.payload;
      const newArray = state.holidayList.map(h => {
        if (h.id == holiday.id) {
          return holiday;
        }
        return h;
      });
      return {
        ...state,
        loading: false,
        holidayList: newArray,
        updateHolidaySuccess: true
      };
    }

    case Types.UPDATE_HOLIDAY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        updateHolidaySuccess: false
      };

    case Types.DELETE_HOLIDAY:
      return {
        ...state,
        loading: true,
        deleteHolidaySuccess: false
      };

    case Types.DELETE_HOLIDAY_SUCCESS: {
      const { id } = action.payload;
      const newArray = state.holidayList.filter(h => h.id != id);
      return {
        ...state,
        loading: false,
        holidayList: newArray,
        totalHolidaysCount: state.totalHolidaysCount-1,
        deleteHolidaySuccess: true
      };
    }

    case Types.DELETE_HOLIDAY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        deleteHolidaySuccess: false
      };

    case Types.RESET_HOLIDAY_STATUS:
      return {
        ...state,
        getHolidaysSuccess: false,
        getUniqueHolidaySuccess: false,
        createHolidaySuccess: false,
        updateHolidaySuccess: false,
        deleteHolidaySuccess: false
      };

    default:
      return state;
  }
};

export const addHoliday = ({
  type,
  stateId,
  townhallId,
  description,
  holidayClass,
  startDate,
  endDate
}, redirectToBack) => ({
  type: Types.ADD_HOLIDAY,
  payload: {
    tipo: type,
    estadoId: stateId,
    prefeituraId: townhallId,
    descricao: description,
    tipoData: holidayClass,
    dataInicio: startDate,
    dataTermino: endDate,
    redirectToBack
  }
});

export const getHolidays = ({
  pageSize,
  page,
  stateId,
  townhallId,
  initialDate,
  endDate,
  townhallName,
  holidayType,
  description
}) => ({
  type: Types.GET_HOLIDAYS,
  payload: {
    pageSize,
    page,
    stateId,
    townhallId,
    initialDate,
    endDate,
    townhallName,
    holidayType,
    description
  }
});

export const getUniqueHoliday = ({ id }) => ({
  type: Types.GET_UNIQUE_HOLIDAY,
  payload: {
    id
  }
});

export const updateHoliday = ({
  id,
  type,
  stateId,
  townhallId,
  description,
  holidayClass,
  startDate,
  endDate,
  history
}) => ({
  type: Types.UPDATE_HOLIDAY,
  payload: {
    id,
    tipo: type,
    estadoId: stateId,
    prefeituraId: townhallId,
    descricao: description,
    tipoData: holidayClass,
    dataInicio: startDate,
    dataTermino: endDate,
    history, 
  }
});

export const deleteHoliday = ({ id }) => ({
  type: Types.DELETE_HOLIDAY,
  payload: {
    id
  }
});

export const resetStatus = () => ({
  type: Types.RESET_HOLIDAY_STATUS
});

export const Types = {
  GET_IRREGULARITIES: "GET_IRREGULARITIES",
  GET_IRREGULARITIES_SUCCESS: "GET_IRREGULARITIES_SUCCESS",
  GET_IRREGULARITIES_ERROR: "GET_IRREGULARITIES_ERROR",

  GET_IRREGULARITY_OPERATIONS: "GET_IRREGULARITY_OPERATIONS",
  GET_IRREGULARITY_OPERATIONS_SUCCESS: "GET_IRREGULARITY_OPERATIONS_SUCCESS",
  GET_IRREGULARITY_OPERATIONS_ERROR: "GET_IRREGULARITY_OPERATIONS_ERROR",

  GET_IRREGULARITY: "GET_IRREGULARITY",
  GET_IRREGULARITY_SUCCESS: "GET_IRREGULARITY_SUCCESS",
  GET_IRREGULARITY_ERROR: "GET_IRREGULARITY_ERROR",

  UPDATE_IRREGULARITY_STATUS: "UPDATE_IRREGULARITY_STATUS",
  UPDATE_IRREGULARITY_STATUS_SUCCESS: "UPDATE_IRREGULARITY_STATUS_SUCCESS",
  UPDATE_IRREGULARITY_STATUS_ERROR: "UPDATE_IRREGULARITY_STATUS_ERROR",

  RESET_IRREGULARITIES_STATUS: "RESET_IRREGULARITIES_STATUS"
};

const INIT_STATE = {
  irregularities: [],
  irregularityOperations: [],
  irregularityOperationsCount: "",
  irregularity: {},
  irregularitiesCount: "",
  loading: false,
  getIrregularitiesSuccess: false,
  getIrregularitiesError: false,
  getIrregularityOperationsSuccess: false,
  getIrregularityOperationsError: false,
  getIrregularitySuccess: false,
  getIrregularityError: false,
  updateIrregularityStatusSuccess: false,
  updateIrregularityStatusError: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.GET_IRREGULARITIES:
      return {
        ...state,
        loading: true,
        getIrregularitiesSuccess: false,
        getIrregularitiesError: false
      };

    case Types.GET_IRREGULARITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        irregularities: action.payload.irregularities,
        irregularitiesCount: action.payload.irregularitiesCount,
        getIrregularitiesSuccess: true
      };

    case Types.GET_IRREGULARITIES_ERROR:
      return {
        ...state,
        loading: false,
        getIrregularitiesError: action.payload.error,
        getIrregularitiesSuccess: false,
      };

    case Types.GET_IRREGULARITY_OPERATIONS:
      return {
        ...state,
        loading: true,
        getIrregularityOperationsSuccess: false,
        getIrregularityOperationsError: false
      };

    case Types.GET_IRREGULARITY_OPERATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        irregularityOperations: action.payload.irregularityOperations,
        irregularityOperationsCount: action.payload.irregularityOperationsCount,
        getIrregularityOperationsSuccess: true
      };

    case Types.GET_IRREGULARITY_OPERATIONS_ERROR:
      return {
        ...state,
        loading: false,
        getIrregularityOperationsError: action.payload.error,
        getIrregularityOperationsSuccess: false,
      };

    case Types.GET_IRREGULARITY:
      return {
        ...state,
        loading: true,
        getIrregularitySuccess: false,
        getIrregularityError: false
      };

    case Types.GET_IRREGULARITY_SUCCESS:
      return {
        ...state,
        loading: false,
        irregularity: action.payload.irregularity,
        getIrregularitySuccess: true
      };

    case Types.GET_IRREGULARITY_ERROR:
      return {
        ...state,
        loading: false,
        getIrregularityError: action.payload.error,
        getIrregularitySuccess: false,
      };

    case Types.UPDATE_IRREGULARITY_STATUS:
      return {
        ...state,
        loading: true,
        updateIrregularityStatusSuccess: false,
        updateIrregularityStatusError: false
      };

    case Types.UPDATE_IRREGULARITY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateIrregularityStatusSuccess: true
      };

    case Types.UPDATE_IRREGULARITY_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        updateIrregularityStatusError: action.payload.error,
        updateIrregularitiyStatusSuccess: false,
      };

    case Types.RESET_IRREGULARITIES_STATUS:
      return {
        ...state,
        getIrregularitiesSuccess: false,
        getIrregularitiesError: false,
        getIrregularitySuccess: false,
        getIrregularityError: false,
        updateIrregularityStatusSuccess: false,
        updateIrregularityStatusError: false,
      };

    default:
      return state;
  }
};

export const getIrregularities = ({
  take,
  skip,
  statusIrregularidade,
  prefeituraId,
  dataInicio,
  dataTermino,
  nome,
  placaVeiculo,
  usuarioId
}) => ({
  type: Types.GET_IRREGULARITIES,
  payload: {
    take,
    skip,
    statusIrregularidade,
    prefeituraId,
    dataInicio,
    dataTermino,
    nome,
    placaVeiculo,
    usuarioId
  }
});

export const getIrregularityOperations = ({
  take,
  skip,
  statusIrregularidade,
  prefeituraId,
  dataInicio,
  dataTermino,
  nome,
  placaVeiculo,
  usuarioId
}) => ({
  type: Types.GET_IRREGULARITY_OPERATIONS,
  payload: {
    take,
    skip,
    statusIrregularidade,
    prefeituraId,
    dataInicio,
    dataTermino,
    nome,
    placaVeiculo,
    usuarioId
  }
});

export const getIrregularity = ({
  id
}) => ({
  type: Types.GET_IRREGULARITY,
  payload: {
    id
  }
});

export const updateIrregularityStatus = ({
  id,
  usuarioId, 
  statusIrregularidade
}) => ({
  type: Types.UPDATE_IRREGULARITY_STATUS,
  payload: {
    id, 
    usuarioId, 
    statusIrregularidade
  }
});

export const resetStatus = () => ({
  type: Types.RESET_IRREGULARITIES_STATUS
});

import { all } from "redux-saga/effects";

import authSagas from "./auth/saga";
import userSagas from "./user/saga";
import deviceSagas from "./device/saga";
import townHallSagas from "./townhall/saga";
import statesSagas from "./states/saga";
import profileSagas from "./profile/saga";
import establishmentSagas from "./establishment/saga";
import addressSagas from "./address/saga";
import regionSagas from "./region/saga";
import holidaySagas from "./holiday/saga";
import irregularitySagas from "./irregularity/saga";
import clientSagas from "./clients/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    userSagas(),
    deviceSagas(),
    townHallSagas(),
    statesSagas(),
    profileSagas(),
    establishmentSagas(),
    addressSagas(),
    regionSagas(),
    holidaySagas(),
    irregularitySagas(),
    clientSagas()
  ]);
}

import { all, call, put, takeLatest } from "redux-saga/effects";
import { Types } from "./index";

import { getAllStates } from "../../services/http/index";

function* getAllStatesAsync() {
  try {
    const statesList = yield call(getAllStates);
    yield put({
      type: Types.GET_STATES_SUCCESS,
      payload: {
        list: statesList.data.data
      }
    });

    // Saving to local storage to persist data
    yield localStorage.setItem('stateList', JSON.stringify(statesList.data.data))
  } catch (error) {
    yield put({
      type: Types.GET_STATES_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

export default function* rootSaga() {
  yield all([yield takeLatest(Types.GET_STATES, getAllStatesAsync)]);
}

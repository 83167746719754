import { all, call, put, takeLatest } from "redux-saga/effects";
import { Types } from "./index";

import {
  getAddress
} from "../../services/http/index";

function* getAddressAsync({ payload }) {
  try {
    const response = yield call(getAddress, payload);

    yield put({
      type: Types.GET_ADDRESS_SUCCESS,
      payload: {
        endereco: response.data.data.logradouro,
        bairro: response.data.data.bairro,
        cidade: response.data.data.localidade,
        uf: response.data.data.uf,
        ibgeCode: response.data.data.ibgeCode
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_ADDRESS_ERROR,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(Types.GET_ADDRESS, getAddressAsync)
  ])
}

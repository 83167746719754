export const Types = {
  GET_DEVICES: "device/GET_DEVICES",
  GET_DEVICES_SUCCESS: "device/GET_DEVICES_SUCCESS",
  GET_DEVICES_ERROR: "device/GET_DEVICES_ERROR",

  ADD_DEVICES: "device/ADD_DEVICES",
  ADD_DEVICES_SUCCESS: "device/ADD_DEVICES_SUCCESS",
  ADD_DEVICES_ERROR: "device/ADD_DEVICES_ERROR",

  UPDATE_DEVICES: "device/UPDATE_DEVICES",
  UPDATE_DEVICES_SUCCESS: "device/UPDATE_DEVICES_SUCCESS",
  UPDATE_DEVICES_ERROR: "device/UPDATE_DEVICES_ERROR",

  DELETE_DEVICES: "device/DELETE_DEVICES",
  DELETE_DEVICES_SUCCESS: "device/DELETE_DEVICES_SUCCESS",
  DELETE_DEVICES_ERROR: "device/DELETE_DEVICES_ERROR",

  GET_DEVICES_MANUFACTURER: "device/GET_DEVICES_MANUFACTURER",
  GET_DEVICES_MANUFACTURER_SUCCESS: "device/GET_DEVICES_MANUFACTURER_SUCCESS",
  GET_DEVICES_MANUFACTURER_ERROR: "device/GET_DEVICES_MANUFACTURER_ERROR",

  ADD_DEVICES_MANUFACTURER: "device/ADD_DEVICES_MANUFACTURER",
  ADD_DEVICES_MANUFACTURER_SUCCESS: "device/ADD_DEVICES_MANUFACTURER_SUCCESS",
  ADD_DEVICES_MANUFACTURER_ERROR: "device/ADD_DEVICES_MANUFACTURER_ERROR",

  UPDATE_DEVICES_MANUFACTURER: "device/UPDATE_DEVICES_MANUFACTURER",
  UPDATE_DEVICES_MANUFACTURER_SUCCESS:
    "device/UPDATE_DEVICES_MANUFACTURER_SUCCESS",
  UPDATE_DEVICES_MANUFACTURER_ERROR: "device/UPDATE_DEVICES_MANUFACTURER_ERROR",

  DELETE_DEVICES_MANUFACTURER: "device/DELETE_DEVICES_MANUFACTURER",
  DELETE_DEVICES_MANUFACTURER_SUCCESS:
    "device/DELETE_DEVICES_MANUFACTURER_SUCCESS",
  DELETE_DEVICES_MANUFACTURER_ERROR: "device/DELETE_DEVICES_MANUFACTURER_ERROR",

  RESET_DEVICES_STATUS: "device/RESET_STATUS"
};

const INIT_STATE = {
  manufacturerList: [],
  list: [],
  errors: null,
  loading: false,
  brandSuccess: false,
  brandError: false,
  deleteSuccess: false,
  deleteBrandSuccess: false,
  devicesBrandCount: 0,
  devicesCount: 0,
  deviceSuccess: false,
  deviceError: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.GET_DEVICES:
      return { ...state, loading: true, deleteSuccess: false, error: "" };
    case Types.GET_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        devicesCount: action.payload.devicesCount,
        errors: ""
      };
    case Types.GET_DEVICES_ERROR:
      return {
        ...state,
        loading: false,
        list: [],
        errors: action.payload.errors
      };
    case Types.GET_DEVICES_MANUFACTURER:
      return {
        ...state,
        loading: true,
        error: "",
        brandSuccess: false,
        brandError: false,
        deleteBrandSuccess: false,
      };
    case Types.GET_DEVICES_MANUFACTURER_SUCCESS:
      return {
        ...state,
        loading: false,
        manufacturerList: action.payload.list,
        errors: "",
        devicesBrandCount: action.payload.devicesBrandCount
      };
    case Types.GET_DEVICES_MANUFACTURER_ERROR:
      return {
        ...state,
        loading: false,
        manufacturerList: [],
        errors: action.payload.errors,
        devicesBrandCount: 0
      };
    case Types.ADD_DEVICES_MANUFACTURER:
      return {
        ...state,
        loading: true,
        brandSuccess: false,
        brandError: false,
        error: ""
      };
    case Types.ADD_DEVICES_MANUFACTURER_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        brandSuccess: true,
        brandError: false
      };
    case Types.ADD_DEVICES_MANUFACTURER_ERROR:
      return {
        ...state,
        loading: false,
        brandSuccess: false,
        brandError: true,
        list: [],
        errors: action.payload.errors
      };

    case Types.ADD_DEVICES:
      return {
        ...state,
        loading: true,
        deviceSuccess: false,
        deviceError: false,
        error: ""
      };
    case Types.ADD_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        deviceSuccess: true,
        deviceError: false
      };
    case Types.ADD_DEVICES_ERROR:
      return {
        ...state,
        loading: false,
        deviceSuccess: false,
        deviceError: true,
        list: [],
        error: action.payload.error
      };

    case Types.UPDATE_DEVICES:
      return {
        ...state,
        loading: true,
        deviceSuccess: false,
        deviceError: false,
        error: ""
      };
    case Types.UPDATE_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        deviceSuccess: true,
        deviceError: false
      };
    case Types.UPDATE_DEVICES_ERROR:
      return {
        ...state,
        loading: false,
        deviceSuccess: false,
        deviceError: true,
        list: [],
        errors: action.payload.errors
      };

    case Types.DELETE_DEVICES:
      return {
        ...state,
        loading: true,
        deviceSuccess: false,
        deviceError: false,
        error: "",
        deleteSuccess: false
      };
    case Types.DELETE_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        deviceSuccess: true,
        deviceError: false,
        deleteSuccess: true
      };
    case Types.DELETE_DEVICES_ERROR:
      return {
        ...state,
        loading: false,
        deviceSuccess: false,
        deviceError: true,
        errors: action.payload.errors,
        deleteSuccess: false
      };

    case Types.UPDATE_DEVICES_MANUFACTURER:
      return {
        ...state,
        loading: true,
        brandSuccess: false,
        brandError: false,
        error: ""
      };
    case Types.UPDATE_DEVICES_MANUFACTURER_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        brandSuccess: true,
        brandError: false
      };
    case Types.UPDATE_DEVICES_MANUFACTURER_ERROR:
      return {
        ...state,
        loading: false,
        brandSuccess: false,
        brandError: true,
        list: [],
        errors: action.payload.errors
      };
    case Types.DELETE_DEVICES_MANUFACTURER:
      return {
        ...state,
        loading: true,
        brandSuccess: false,
        brandError: false,
        error: "",
        deleteBrandSuccess: false
      };
    case Types.DELETE_DEVICES_MANUFACTURER_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        brandSuccess: true,
        brandError: false,
        deleteBrandSuccess: true
      };
    case Types.DELETE_DEVICES_MANUFACTURER_ERROR:
      return {
        ...state,
        loading: false,
        brandSuccess: false,
        brandError: true,
        list: [],
        errors: action.payload.errors,
        deleteBrandSuccess: false
      };
    
    case Types.RESET_DEVICES_STATUS:
      return {
        ...state,
        deviceSuccess: false,
        deviceError: false,
        brandSuccess: false,
        brandError: false,
      }

    default:
      return { ...state };
  }
};

export const getDevices = ({
  pageSize,
  identificadorAparelho = "",
  codigo = "",
  tipo = "",
  modelo = "",
  page = 1,
  brandName = "",
  townhall = "",
  stateName = ""
}) => ({
  type: Types.GET_DEVICES,
  payload: {
    page,
    pageSize,
    codigo,
    identificadorAparelho,
    tipo,
    modelo,
    brandName,
    townhall,
    stateName
  }
});

export const addDevices = (device, redirectToList) => ({
  type: Types.ADD_DEVICES,
  payload: {
    device,
    redirectToList
  }
});

export const deleteDevice = ({ id }) => ({
  type: Types.DELETE_DEVICES,
  payload: {
    id
  }
});

export const updateDevices = (device, redirectToList) => ({
  type: Types.UPDATE_DEVICES,
  payload: {
    device,
    redirectToList
  }
});

export const deleteDevices = ({ id }) => ({
  type: Types.DELETE_DEVICES,
  payload: { id }
});

export const addDevicesManufacturer = ({ nome, redirectToList }) => ({
  type: Types.ADD_DEVICES_MANUFACTURER,
  payload: { nome, redirectToList }
});

export const getDevicesManufacturer = ({
  pageSize,
  page = 1,
  nome = "",
  codigo = "",
}) => ({
  type: Types.GET_DEVICES_MANUFACTURER,
  payload: { pageSize, nome, codigo, page }
});

export const updateDevicesManufacturer = ({ nome, id, redirectToList }) => ({
  type: Types.UPDATE_DEVICES_MANUFACTURER,
  payload: { nome, id, redirectToList }
});

export const deleteDevicesManufacturer = ({ id }) => ({
  type: Types.DELETE_DEVICES_MANUFACTURER,
  payload: { id }
});

export const resetDeviceStatus = () => ({
  type: Types.RESET_DEVICES_STATUS,
})

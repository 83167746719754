export const Types = {
  GET_PROFILES: "profile/GET_PROFILES",
  GET_PROFILES_SUCCESS: "profile/GET_PROFILES_SUCCESS",
  GET_PROFILES_ERROR: "profile/GET_PROFILES_ERROR",

  GET_PROFILES_SIMPLE: "profile/GET_PROFILES_SIMPLE",
  GET_PROFILES_SIMPLE_SUCCESS: "profile/GET_PROFILES_SIMPLE_SUCCESS",
  GET_PROFILES_SIMPLE_ERROR: "profile/GET_PROFILES_SIMPLE_ERROR",

  GET_PROFILE: "profile/GET_PROFILE",
  GET_PROFILE_SUCCESS: "profile/GET_PROFILE_SUCCESS",
  GET_PROFILE_ERROR: "profile/GET_PROFILE_ERROR",

  GET_PROFILE_CONFIGS: "profile/GET_PROFILE_CONFIGS",
  GET_PROFILE_CONFIGS_SUCCESS: "profile/GET_PROFILE_CONFIGS_SUCCESS",
  GET_PROFILE_CONFIGS_ERROR: "profile/GET_PROFILE_CONFIGS_ERROR",

  ADD_PROFILES: "profile/ADD_PROFILES",
  ADD_PROFILES_SUCCESS: "profile/ADD_PROFILES_SUCCESS",
  ADD_PROFILES_ERROR: "profile/ADD_PROFILES_ERROR",

  UPDATE_PROFILES: "profile/UPDATE_PROFILES",
  UPDATE_PROFILES_SUCCESS: "profile/UPDATE_PROFILES_SUCCESS",
  UPDATE_PROFILES_ERROR: "profile/UPDATE_PROFILES_ERROR",

  DELETE_PROFILES: "profile/DELETE_PROFILES",
  DELETE_PROFILES_SUCCESS: "profile/DELETE_PROFILES_SUCCESS",
  DELETE_PROFILES_ERROR: "profile/DELETE_PROFILES_ERROR",

  RESET_PROFILES_STATUS: "profile/RESET_PROFILES_STATUS"
};

const INIT_STATE = {
  manufacturerList: [],
  list: [],
  errors: [],
  profileConfigs: [],
  loading: false,
  profileSuccess: false,
  profileError: false,
  deleteProfileSuccess: false,
  deleteProfileError: false,
  profilesCount: 1,
  current: null,
  simple: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.GET_PROFILE:
      return { ...state, loading: true, deleteSuccess: false, error: "", current: null, };
    case Types.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        current: action.payload.current,
        errors: "",      };
    case Types.GET_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        list: [],
        current: null,
      };

    case Types.GET_PROFILES:
      return { ...state, loading: true, deleteSuccess: false, error: "" };
    case Types.GET_PROFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        profilesCount: action.payload.profilesCount,
        errors: "",      };
    case Types.GET_PROFILES_ERROR:
      return {
        ...state,
        loading: false,
        list: [],
        errors: action.payload.errors,
        profilesCount: 1
      };

      case Types.GET_PROFILES_SIMPLE:
        return { ...state, loading: true, deleteSuccess: false, error: "" };
      case Types.GET_PROFILES_SIMPLE_SUCCESS:
        return {
          ...state,
          loading: false,
          simple: action.payload.simple,
          errors: "",      };
      case Types.GET_PROFILES_SIMPLE_ERROR:
        return {
          ...state,
          loading: false,
          simple: [],
          errors: action.payload.errors,
          profilesCount: 1
        };

    case Types.GET_PROFILE_CONFIGS:
      return { ...state, loading: true, deleteSuccess: false, error: "" };
    case Types.GET_PROFILE_CONFIGS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        profileConfigs: action.payload.profileConfigs
      };
    case Types.GET_PROFILE_CONFIGS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
        profileConfigs: []
      };

    case Types.ADD_PROFILES:
      return {
        ...state,
        loading: true,
        addProfileSuccess: false,
        addProfileError: false,
        error: ""
      };
    case Types.ADD_PROFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        addProfileSuccess: true,
        addProfileError: false
      };
    case Types.ADD_PROFILES_ERROR:
      return {
        ...state,
        loading: false,
        addProfileSuccess: false,
        addProfileError: true,
        list: [],
        errors: action.payload.errors
      };

    case Types.UPDATE_PROFILES:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case Types.UPDATE_PROFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        addProfileSuccess: true,
      };
    case Types.UPDATE_PROFILES_ERROR:
      return {
        ...state,
        loading: false,
        list: [],
        errors: action.payload.errors
      };

    case Types.DELETE_PROFILES:
      return {
        ...state,
        loading: true,
        error: "",
        deleteSuccess: false
      };
    case Types.DELETE_PROFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        deleteSuccess: true
      };
    case Types.DELETE_PROFILES_ERROR:
      return {
        ...state,
        loading: false,
        list: [],
        errors: action.payload.errors,
        deleteSuccess: false
      };
    case Types.RESET_PROFILES_STATUS:
      return {
        ...state,
        addProfileSuccess: false,
        addProfileError: false,
        current: null
      };
    default:
      return { ...state };
  }
};


export const getProfile = ({
  id
}) => ({
  type: Types.GET_PROFILE,
  payload: {
    id
  }
});

export const getProfiles = ({
  id = "",
  pageSize = 0,
  page = 0,
  nome = "",
  descricao = "",
  ativo = ""
}) => ({
  type: Types.GET_PROFILES,
  payload: {
    id,
    pageSize,
    page,
    nome,
    descricao,
    ativo
  }
});

export const getProfilesSimple = () => ({
  type: Types.GET_PROFILES_SIMPLE,
  payload: {}
});

export const getProfileConfigs = profile => ({
  type: Types.GET_PROFILE_CONFIGS,
  payload: profile
});

export const addProfile = (profile, redirectToList) => ({
  type: Types.ADD_PROFILES,
  payload: {
    profile,
    redirectToList
  }
});

export const updateProfile = (profile, redirectToList) => ({
  type: Types.UPDATE_PROFILES,
  payload: {
    profile,
    redirectToList
  }
});

export const deleteProfile = () => ({
  type: Types.RESET_PROFILES_STATUS
});

export const resetProfileStatus = () => ({
  type: Types.RESET_PROFILES_STATUS
});


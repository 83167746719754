import { all, call, put, takeEvery } from "redux-saga/effects";
import { auth } from "../../helpers/Firebase";
import {
  Types,
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  setPermissions
} from "./index";

import {
  login,
  getProfile,
  getAcessoUser,
  getTownHallUsers
} from "../../services/http/index";

function* loginWithEmailPassword({ payload }) {
  const { history } = payload;
  try {
    const loginUser = yield call(login, payload.user);
    const userId = loginUser.data[".id"];

    localStorage.setItem("token", loginUser.data.access_token);
    localStorage.setItem("refreshToken", loginUser.data[".refreshToken"]);
    localStorage.setItem("userId", userId);
    localStorage.setItem("lastLogin", new Date());

    const perfilAcesso = yield call(getAcessoUser, { userId });
    const perfilAcessoId = perfilAcesso.data.data[0]?.perfilAcesso?.id;
    const profile = yield call(getProfile, { id: perfilAcessoId });

    loginUser.data.permissions = profile.data.data.menusAcesso;

    localStorage.setItem(
      "permissions",
      JSON.stringify(profile.data.data.menusAcesso)
    );

    yield put(loginUserSuccess(loginUser.data));
    history.push("/");
  } catch (error) {
    yield put(loginUserError(error.response.data.errors[0].message));
  }
}

const registerWithEmailPasswordAsync = async (email, password) =>
  auth
    .createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      localStorage.setItem("user_id", registerUser.user.uid);
      yield put(registerUserSuccess(registerUser));
      history.push("/");
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

const logoutAsync = async history => {
  await auth
    .signOut()
    .then(authUser => authUser)
    .catch(error => error);
  history.push("/");
};

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield call(logoutAsync, history);
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("permissions");
  } catch (error) {
    console.error(error);
  }
}

const forgotPasswordAsync = async email => {
  return auth
    .sendPasswordResetEmail(email)
    .then(user => user)
    .catch(error => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess("success"));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then(user => user)
    .catch(error => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess("success"));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

function* getPermissionsAsync() {
  try {
    const profile = JSON.parse(localStorage.getItem("permissions"));
    yield put(setPermissions(profile));
  } catch (error) {
    console.log(error);
    yield put(loginUserError(error.response.data.errors[0].message));
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(Types.LOGIN_USER, loginWithEmailPassword),
    yield takeEvery(Types.LOGOUT_USER, logout),
    yield takeEvery(Types.REGISTER_USER, registerWithEmailPassword),
    yield takeEvery(Types.FORGOT_PASSWORD, forgotPassword),
    yield takeEvery(Types.RESET_PASSWORD, resetPassword),
    yield takeEvery(Types.GET_PERMISSIONS, getPermissionsAsync)
  ]);
}

export const Types = {
  GET_USERS: "user/GET_USERS",
  GET_USERS_SUCCESS: "user/GET_USERS_SUCCESS",
  GET_USERS_ERROR: "user/GET_USERS_ERROR",

  GET_USER_PROFILES: "user/GET_USER_PROFILES",
  GET_USER_PROFILES_SUCCESS: "user/GET_USER_PROFILES_SUCCESS",
  GET_USER_PROFILES_ERROR: "user/GET_USER_PROFILES_ERROR",

  GET_UNIQUE_USER: "user/GET_UNIQUE_USER",
  GET_UNIQUE_USER_SUCCESS: "user/GET_UNIQUE_USER_SUCCESS",
  GET_UNIQUE_USER_ERROR: "user/GET_UNIQUE_USER_ERROR",

  ADD_USER: "user/ADD_USER",
  ADD_USER_SUCCESS: "user/ADD_USER_SUCCESS",
  ADD_USER_ERROR: "user/ADD_USER_ERROR",

  EDIT_USER: "user/EDIT_USER",
  EDIT_USER_SUCCESS: "user/EDIT_USER_SUCCESS",
  EDIT_USER_ERROR: "user/EDIT_USER_ERROR",

  DELETE_USER: "user/DELETE_USER",
  DELETE_USER_SUCCESS: "user/DELETE_USER_SUCCESS",
  DELETE_USER_ERROR: "user/DELETE_USER_ERROR",

  RESET_STATUS: "user/RESET_STATUS",
};

const INIT_STATE = {
  selectedUser: {},
  list: [],
  userProfileList: [],
  errors: [],
  loading: false,
  userCount: 0,
  addUserSuccess: false,
  addUserError: false,
  editUserSuccess: false,
  editUserError: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.GET_USERS:
      return { ...state, loading: true, error: "" };
    case Types.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        userCount: action.payload.userCount,
        errors: ""
      };
    case Types.GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        list: [],
        errors: action.payload.errors
      };

    case Types.GET_UNIQUE_USER:
      return { ...state, loading: true, error: "" };
    
    case Types.GET_UNIQUE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedUser: action.payload.selectedUser
      }

    case Types.GET_UNIQUE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.errors
      }

    case Types.ADD_USER:
      return {
        ...state,
        loading: true,
        errors: "",
      }
    case Types.ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: true,
        addUserError: false
      }  
    case Types.ADD_USER_ERROR:
      return {
        ...state,
        loading: false,
        addUserSuccess: false,
        addUserError: true,
        errors: action.payload.errors,
      }
    case Types.EDIT_USER:
      return {
        ...state,
        loading: true,
        errors: "",
      }
    case Types.EDIT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedUser: action.payload.user,
        editUserSuccess: true,
        editUserError: false
      }  
    case Types.EDIT_USER_ERROR:
      return {
        ...state,
        loading: false,
        editUserSuccess: false,
        editUserError: true,
        errors: action.payload.errors,
      }
    case Types.DELETE_USER:
      return {
        ...state,
        loading: true,
        errors: ""
      }
    case Types.DELETE_USER_SUCCESS: {
      const { id } = action.payload;
      const newArray = state.list.filter(item => item.id !== id)

      return {
        ...state,
        list: newArray,
        loading: false,
        userCount: state.userCount - 1 
      }
    }
    case Types.DELETE_USER_ERROR: 
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.GET_USER_PROFILES:
      return {
        ...state,
        loading: true,
      }

    case Types.GET_USER_PROFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfileList: action.payload.profiles,
      }

    case Types.GET_USER_PROFILES_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.RESET_STATUS:
      return {
        ...state,
        ...INIT_STATE
      }
    default:
      return { ...state };
  }
};

export const getUsers = ({
  page = "",
  pageSize,
  nome = "",
  codigo = "",
  cpf = "",
  estabelecimentoId = "",
  prefeituraId="",
  status="",
}) => ({
  type: Types.GET_USERS,
  payload: { page, pageSize, nome, codigo, cpf, estabelecimentoId, prefeituraId, status }
});

export const addUser = (user, redirectToBack, profileList) => ({
  type: Types.ADD_USER,
  payload: {
    user,
    redirectToBack,
    profileList
  }
})

export const resetStatus = () => ({
  type: Types.RESET_STATUS,
})

export const getUniqueUser = (id) => ({
  type: Types.GET_UNIQUE_USER,
  payload: { id }
})

export const editUser = (user, redirectToBack, profileList, profilesToDelete) => ({
  type: Types.EDIT_USER,
  payload: {
    user,
    redirectToBack,
    profileList,
    profilesToDelete
  }
})

export const deleteUser = (id) => ({
  type: Types.DELETE_USER,
  payload: {
    id
  }
})

export const getUserProfiles = (userId) => ({
  type: Types.GET_USER_PROFILES,
  payload: {
    userId
  }
})
import { all, call, put, takeLatest } from "redux-saga/effects";
import { Types } from "./index";
import { Alert } from '../../helpers/Alert';
import FormatErrors from '../../helpers/Errors';

import {
  getEstablishments,
  addEstablishment,
  getUniqueEstablishment,
  editEstablishment,
  removeEstablishment,
  addEstablishmentEmployees,
  removeEstablishmentEmployees, 
  getEstablishmentEmployees,
  getMasterEstablishmentCadPurchaseHistory,
  getMasterEstablishmentCadSellHistory
} from "../../services/http/index";

function* getEstablishmentsAsync({ payload }) {
  try {
    const response = yield call(getEstablishments, payload);

    yield put({
      type: Types.GET_ESTABLISHMENTS_SUCCESS,
      payload: {
        list: response.data.data,
        establishmentsCount: response.data.count
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_ESTABLISHMENTS_ERROR,
    });
    Alert({
      type: 'error',
      title: FormatErrors(error)
    })
  }
}

function* addEstablishmentAsync({ payload }) {
  try {
    yield call(addEstablishment, payload);

    yield put({
      type: Types.ADD_ESTABLISHMENT_SUCCESS,
    });
    Alert({
      type: 'success',
      title: 'Estabelecimento adicionado com sucesso!'
    })
  } catch (error) {
    yield put({
      type: Types.ADD_ESTABLISHMENT_ERROR,
    });
    Alert({
      type: 'error',
      title: FormatErrors(error)
    })
  }
}

function* getUniqueEstablishmentAsync({ payload }) {
  try {
    const response = yield call(getUniqueEstablishment, payload);

    yield put({
      type: Types.GET_UNIQUE_ESTABLISHMENT_SUCCESS,
      payload: {
        selectedEstablishment: response.data.data,
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_UNIQUE_ESTABLISHMENT_ERROR
    });
    Alert({
      type: 'error',
      title: FormatErrors(error)
    })
  }
}

function* editEstablishmentAsync({ payload }) {
  try {
    yield call(editEstablishment, payload);

    yield put({
      type: Types.EDIT_ESTABLISHMENT_SUCCESS,
    });
    Alert({
      type: 'success',
      title: 'Estabelecimento atualizado com sucesso!'
    })
  } catch (error) {
    yield put({
      type: Types.EDIT_ESTABLISHMENT_ERROR,
    });
    Alert({
      type: 'error',
      title: FormatErrors(error)
    })
  }
}

function* removeEstablishmentAsync({ payload }) {
  try {
    yield call(removeEstablishment, payload);

    yield put({
      type: Types.REMOVE_ESTABLISHMENT_SUCCESS,
    });
    Alert({
      type: 'success',
      title: 'Estabelecimento removido com sucesso!'
    })
  } catch (error) {
    yield put({
      type: Types.REMOVE_ESTABLISHMENT_ERROR,
    });
    Alert({
      type: 'error',
      title: FormatErrors(error)
    })
  }
}

function* addEstablishmentEmployeesAsync({ payload }) {
  try {
    yield call(addEstablishmentEmployees, payload);

    yield put({
      type: Types.ADD_ESTABLISHMENT_EMPLOYEES_SUCCESS,
    });
    Alert({
      type: 'success',
      title: 'Usuários vinculados ao estabelecimento com sucesso!'
    })
  } catch (error) {  
    yield put({
      type: Types.ADD_ESTABLISHMENT_EMPLOYEES_ERROR,
    });
    Alert({
      type: 'error',
      title: FormatErrors(error)
    })
  }
}

function* removeEstablishmentEmployeesAsync({ payload }) {
  try {
    yield call(removeEstablishmentEmployees, payload);

    yield put({
      type: Types.REMOVE_ESTABLISHMENT_EMPLOYEES_SUCCESS,
    });
    Alert({
      type: 'success',
      title: 'Usuários desvínculados do estabelecimento com sucesso!'
    })
  } catch (error) {
    yield put({
      type: Types.REMOVE_ESTABLISHMENT_EMPLOYEES_ERROR,
    });
    Alert({
      type: 'error',
      title: FormatErrors(error)
    })
  }
}

function* getEstablishmentEmployeesAsync({ payload }) {
  try {
    const response = yield call(getEstablishmentEmployees, payload);

    yield put({
      type: Types.GET_ESTABLISHMENT_EMPLOYEES_SUCCESS,
      payload: {
        listEmployees: response.data.data,
        listEmployeesCount: response.data.count
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_ESTABLISHMENT_EMPLOYEES_ERROR,
    });
    Alert({
      type: 'error',
      title: FormatErrors(error)
    })
  }
}

function* getMasterEstablishmentCadsPurchaseHistoryAsync({ payload }) {
  try {
    const response = yield call(getMasterEstablishmentCadPurchaseHistory, payload);

    yield put({
      type: Types.GET_MASTER_ESTABLISHMENT_CADS_PURCHASE_HISTORY_SUCCESS,
      payload: {
        masterEstablishmentCadsPurchaseHistory: response.data.data,
        masterEstablishmentCadsPurchaseHistoryCount: response.data.count 
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_MASTER_ESTABLISHMENT_CADS_PURCHASE_HISTORY_ERROR,
    });
  }
}

function* getMasterEstablishmentCadsSellHistoryAsync({ payload }) {
  try {
    const response = yield call(getMasterEstablishmentCadSellHistory, payload);

    yield put({
      type: Types.GET_MASTER_ESTABLISHMENT_CADS_SELL_HISTORY_SUCCESS,
      payload: {
        masterEstablishmentCadsSellHistory: response.data.data,
        masterEstablishmentCadsSellHistoryCount: response.data.count 
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_MASTER_ESTABLISHMENT_CADS_SELL_HISTORY_ERROR,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(Types.GET_ESTABLISHMENTS, getEstablishmentsAsync),
    yield takeLatest(Types.ADD_ESTABLISHMENT, addEstablishmentAsync),
    yield takeLatest(Types.GET_UNIQUE_ESTABLISHMENT, getUniqueEstablishmentAsync),
    yield takeLatest(Types.EDIT_ESTABLISHMENT, editEstablishmentAsync),
    yield takeLatest(Types.REMOVE_ESTABLISHMENT, removeEstablishmentAsync),
    yield takeLatest(Types.ADD_ESTABLISHMENT_EMPLOYEES, addEstablishmentEmployeesAsync),
    yield takeLatest(Types.REMOVE_ESTABLISHMENT_EMPLOYEES, removeEstablishmentEmployeesAsync),
    yield takeLatest(Types.GET_ESTABLISHMENT_EMPLOYEES, getEstablishmentEmployeesAsync),
    yield takeLatest(Types.GET_MASTER_ESTABLISHMENT_CADS_PURCHASE_HISTORY, getMasterEstablishmentCadsPurchaseHistoryAsync),
    yield takeLatest(Types.GET_MASTER_ESTABLISHMENT_CADS_SELL_HISTORY, getMasterEstablishmentCadsSellHistoryAsync)
  ])
}
import { all, call, put, takeLatest } from "redux-saga/effects";
import { Types } from "./index";
import { Alert } from '../../helpers/Alert';
import FormattErrors from '../../helpers/Errors';

import {
  getProfilesConfig,
  addProfile,
  getProfiles,
  getProfile,
  updateProfile,
  getProfilesSimple
} from "../../services/http/index";

function* getProfilesConfigAsync({ payload }) {
  try {
    const response = yield call(getProfilesConfig, payload);

    yield put({
      type: Types.GET_PROFILE_CONFIGS_SUCCESS,
      payload: {
        profileConfigs: response.data.data
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_PROFILE_CONFIGS_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* getProfilesAsync({ payload }) {
  try {
    const response = yield call(getProfiles, payload);

    yield put({
      type: Types.GET_PROFILES_SUCCESS,
      payload: {
        list: response.data.data,
        profilesCount: response.data.count
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_PROFILES_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* getProfileAsync({ payload }) {
  try {
    const response = yield call(getProfile, payload);
    yield put({
      type: Types.GET_PROFILE_SUCCESS,
      payload: {
        current: response.data.data
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_PROFILE_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* getProfilesSimpleAsync({ payload }) {
  try {
    const response = yield call(getProfilesSimple, payload);
    yield put({
      type: Types.GET_PROFILES_SIMPLE_SUCCESS,
      payload: {
        simple: response.data.data
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_PROFILES_SIMPLE_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* addProfilesAsync({ payload }) {
  try {
    const { profile, redirectToList } = payload;
    const response = yield call(addProfile, profile);

    yield put({
      type: Types.ADD_PROFILES_SUCCESS,
      payload: {
        profileConfigs: response.data.data
      }
    });
    Alert({
      type: "success",
      title: "Perfil adicionado com sucesso!"
    }).then(() => redirectToList())
  } catch (error) {
    yield put({
      type: Types.ADD_PROFILES_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
    Alert({
      type: "error",
      title: FormattErrors(error)
    })
  }
}

function* updateProfilesAsync({ payload }) {
  try {
    const { profile, redirectToList } = payload;
    const response = yield call(updateProfile, profile);

    yield put({
      type: Types.UPDATE_PROFILES_SUCCESS,
      payload: {
        // profileConfigs: response.data.data,
      }
    });
    Alert({
      type: "success",
      title: "Perfil atualizado com sucesso!"
    }).then(() => redirectToList())
    yield put({
      type: Types.RESET_PROFILES_STATUS
    })
  } catch (error) {
    yield put({
      type: Types.UPDATE_PROFILES_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
    Alert({
      type: "error",
      title: FormattErrors(error)
    })
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(Types.GET_PROFILES, getProfilesAsync),
    yield takeLatest(Types.GET_PROFILE_CONFIGS, getProfilesConfigAsync),
    yield takeLatest(Types.ADD_PROFILES, addProfilesAsync),
    yield takeLatest(Types.UPDATE_PROFILES, updateProfilesAsync),
    yield takeLatest(Types.GET_PROFILE, getProfileAsync),
    yield takeLatest(Types.GET_PROFILES_SIMPLE, getProfilesSimpleAsync)
  ]);
}

import { all, call, put, takeLatest } from "redux-saga/effects";
import { get } from "sortablejs";
import {
  Types,
} from "./index";

import { 
  getUsers, 
  addUser, 
  getUniqueUser, 
  editUser, 
  addPerfilAccessToUser, 
  deleteUser, 
  getProfileAccess,
  removePerfilAccess
} from "../../services/http/index";
import { Alert } from "../../helpers/Alert"; 
import FormattError from "../../helpers/Errors"; 

function* getUsersAsync({ payload }) {
  try {
    const response = yield call(getUsers, payload);

    yield put({
      type: Types.GET_USERS_SUCCESS,
      payload: {
        userCount: response.data.count,
        list: response.data.data
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_USERS_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
    Alert({
      title: FormattError(error),
      type: "error"
    })
  }
}

function* getUniqueUserAsync({ payload }) {
  try {
    const response = yield call(getUniqueUser, payload);

    yield put({
      type: Types.GET_UNIQUE_USER_SUCCESS,
      payload: {
        selectedUser: response.data.data,
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_UNIQUE_USER_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
    Alert({
      title: FormattError(error),
      type: "error"
    })
  }
}

function* addUserAsync({ payload }) {
  try {
    const { profileList, user, redirectToBack } = payload;

    const response = yield call(addUser, user);

    yield all(profileList.map(item => {
      return call(addPerfilAccessToUser, { userId: response.data.data.id, perfilId: item.id})
    }))

    yield put({
      type: Types.ADD_USER_SUCCESS
    });
    Alert({
      title: "Usuário adicionado com sucesso!",
      type: "success"
    }).then(() => redirectToBack())
  } catch (error) {
    yield put({
      type: Types.ADD_USER_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
    Alert({
      title: FormattError(error),
      type: "error"
    })
  }
}

function* editUserAsync({ payload }) {
  try {
    const { redirectToBack, profileList, profilesToDelete } = payload;
    const response = yield call(editUser, payload);

    yield all(profileList.map(profile => {
      if (profile.new) {
        return call(addPerfilAccessToUser, { userId: response.data.data.id, perfilId: profile.id})
      }
    }))

    yield all(profilesToDelete.map(profile => {
      return call(removePerfilAccess, {id: profile.accessPerfilId})
    }))

    yield put({
      type: Types.EDIT_USER_SUCCESS,
      payload: {
        user: response.data.data
      }
    });
    Alert({
      title: "Usuário atualizado com sucesso!",
      type: "success"
    }).then(() => redirectToBack())
  } catch (error) {
    yield put({
      type: Types.EDIT_USER_ERROR,
      payload: {
        errors: error.response
      }
    });
    Alert({
      title: FormattError(error),
      type: "error"
    })
  }
}

function* deleteUserAsync({ payload }) {
  try {
    yield call(deleteUser, payload);

    yield put({
      type: Types.DELETE_USER_SUCCESS,
      payload: {
        id: payload.id
      }
    })

    Alert({
      title: "Usuário desativado com sucesso!",
      type: "success"
    })
  } catch (err) {
    yield put({
      type: Types.DELETE_USER_ERROR,
      payload: {
        errors: err
      }
    })

    Alert({
      title: FormattError(err),
      type: "error"
    })
  }
}

function* getUserProfilesAsync({ payload }) {
  try {
    const response = yield call(getProfileAccess, payload);
    
    const filteredProfiles = response.data.data.map(profile => ({
      id: profile.id,
      perfilAcesso: profile.perfilAcesso
    }))

    yield put({
      type: Types.GET_USER_PROFILES_SUCCESS,
      payload: {
        profiles: filteredProfiles
      }
    })

  } catch (error) {
    yield put({
      type: Types.GET_USER_PROFILES_ERROR,
      payload: {
        errors: error
      }
    })

    Alert({
      title: FormattError(error),
      type: "error"
    })
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(Types.GET_USERS, getUsersAsync),
    yield takeLatest(Types.GET_UNIQUE_USER, getUniqueUserAsync),
    yield takeLatest(Types.ADD_USER, addUserAsync),
    yield takeLatest(Types.EDIT_USER, editUserAsync),
    yield takeLatest(Types.DELETE_USER, deleteUserAsync),
    yield takeLatest(Types.GET_USER_PROFILES, getUserProfilesAsync)
  ]);
}

export const Types = {
  GET_TOWNHALLS: "GET_TOWNHALLS",
  GET_TOWNHALLS_SUCCESS: "GET_TOWNHALLS_SUCCESS",
  GET_TOWNHALLS_ERROR: "GET_TOWNHALLS_ERROR",

  GET_UNIQUE_TOWNHALL: "GET_UNIQUE_TOWNHALL",
  GET_UNIQUE_TOWNHALL_SUCCESS: "GET_UNIQUE_TOWNHALL_SUCCESS",
  GET_UNIQUE_TOWNHALL_ERROR: "GET_UNIQUE_TOWNHALL_ERROR",

  GET_INCLUDED_USERS: "GET_INCLUDED_USERS",
  GET_INCLUDED_USERS_SUCCESS: "GET_INCLUDED_USERS_SUCCESS",
  GET_INCLUDED_USERS_ERROR: "GET_INCLUDED_USERS_ERROR",

  ADD_TOWNHALLS: "ADD_TOWNHALLS",
  ADD_TOWNHALLS_SUCCESS: "ADD_TOWNHALLS_SUCCESS",
  ADD_TOWNHALLS_ERROR: "ADD_TOWNHALLS_ERROR",

  EDIT_TOWNHALLS: "EDIT_TOWNHALLS",
  EDIT_TOWNHALLS_SUCCESS: "EDIT_TOWNHALLS_SUCCESS",
  EDIT_TOWNHALLS_ERROR: "EDIT_TOWNHALLS_ERROR",

  DELETE_TOWNHALL: "DELETE_TOWNHALL",
  DELETE_TOWNHALL_SUCCESS: "DELETE_TOWNHALL_SUCCESS",
  DELETE_TOWNHALL_ERROR: "DELETE_TOWNHALL_ERROR",

  ADD_TOWNHALL_USERS: "ADD_TOWNHALL_USERS",
  ADD_TOWNHALL_USERS_SUCCESS: "ADD_TOWNHALL_USERS_SUCCESS",
  ADD_TOWNHALL_USERS_ERROR: "ADD_TOWNHALL_USERS_ERROR",

  REMOVE_TOWNHALL_USERS: "REMOVE_TOWNHALL_USERS",
  REMOVE_TOWNHALL_USERS_SUCCESS: "REMOVE_TOWNHALL_USERS_SUCCESS",
  REMOVE_TOWNHALL_USERS_ERROR: "REMOVE_TOWNHALL_USERS_ERROR",

  GET_TOWNHALL_WALLET: "GET_TOWNHALL_WALLET",
  GET_TOWNHALL_WALLET_SUCCESS: "GET_TOWNHALL_WALLET_SUCCESS",
  GET_TOWNHALL_WALLET_ERROR: "GET_TOWNHALL_WALLET_ERROR",

  ADD_TOWNHALL_CAD: "ADD_TOWNHALL_CAD",
  ADD_TOWNHALL_CAD_SUCCESS: "ADD_TOWNHALL_CAD_SUCCESS",
  ADD_TOWNHALL_CAD_ERROR: "ADD_TOWNHALL_CAD_ERROR",

  GET_TOWNHALL_CADS: "GET_TOWNHALL_CADS",
  GET_TOWNHALL_CADS_SUCCESS: "GET_TOWNHALL_CADS_SUCCESS",
  GET_TOWNHALL_CADS_ERROR: "GET_TOWNHALL_CADS_ERROR",

  GET_TOWNHALL_REVENUES_REPORT: "GET_TOWNHALL_REVENUES_REPORT",
  GET_TOWNHALL_REVENUES_REPORT_SUCCESS: "GET_TOWNHALL_REVENUES_REPORT_SUCCESS",
  GET_TOWNHALL_REVENUES_REPORT_ERROR: "GET_TOWNHALL_REVENUES_REPORT_ERROR",

  // Reset all the success and error status
  RESET_TOWNHALL_STATUS: "RESET_TOWNHALL_STATUS"
};

const INIT_STATE = {
  list: [],

  uniqueTownHall: {},

  includedUsersList: [],

  townHallCads: [],
  townHallCadsCount: "",

  townHallRevenuesReport: [],

  includedUsersCount: "",
  townHallsCount: "",
  errors: [],
  loading: false,

  addTownHallSuccess: false,
  addTownHallError: false,

  deleteTownHallSuccess: false,
  deleteTownHallError: false,

  editTownHallSuccess: false,
  editTownHallError: false,

  addTownHallUsersSuccess: false,
  addTownHallUsersError: false,

  addTownHallCadSuccess: false,
  addTownHallCadError: false,

  getTownHallCadsSuccess: false,
  getTownHallCadsError: false,

  townHallWallet: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.GET_TOWNHALLS:
      return {
        ...state,
        loading: true,
        errors: ""
      };

    case Types.GET_TOWNHALLS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        townHallsCount: action.payload.count,
        errors: ""
      };

    case Types.GET_TOWNHALLS_ERROR:
      return {
        ...state,
        loading: false,
        list: [],
        errors: action.payload.errors
      };

    case Types.GET_UNIQUE_TOWNHALL:
      return {
        ...state,
        loading: true,
        errors: ""
      };

    case Types.GET_UNIQUE_TOWNHALL_SUCCESS:
      return {
        ...state,
        loading: false,
        uniqueTownHall: action.payload.townHall,
        errors: ""
      };

    case Types.GET_UNIQUE_TOWNHALL_ERROR:
      return {
        ...state,
        loading: false,
        uniqueTownHall: {},
        errors: action.payload.errors
      };

    case Types.GET_INCLUDED_USERS:
      return {
        ...state,
        loading: true,
        errors: ""
      };

    case Types.GET_INCLUDED_USERS_SUCCESS:
      return {
        ...state,
        includedUsersList: action.payload.includedUsersList,
        includedUsersCount: action.payload.count,
        loading: false,
        errors: ""
      };

    case Types.GET_INCLUDED_USERS_ERROR:
      return {
        ...state,
        includedUsersList: [],
        includedUsersCount: "",
        loading: false,
        errors: action.payload.errors
      };

    case Types.ADD_TOWNHALLS:
      return {
        ...state,
        loading: true,
        errors: "",
        addTownHallSuccess: false,
        addTownHallError: false
      };

    case Types.ADD_TOWNHALLS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        addTownHallSuccess: true,
        addTownHallError: false
      };

    case Types.ADD_TOWNHALLS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
        addTownHallSuccess: false,
        addTownHallError: true
      };

    case Types.EDIT_TOWNHALLS:
      return {
        ...state,
        loading: true,
        errors: "",
        editTownHallSuccess: false,
        editTownHallError: false
      };

    case Types.EDIT_TOWNHALLS_SUCCESS: {
      const newList = state.list.filter((list) => list.id !== action.payload.id)
      newList.push(action.payload.townHall)
      return {
        ...state,
        loading: false,
        errors: "",
        list: newList,
        uniqueTownHall:{ ...state.uniqueTownHall, ...action.payload.townHall},
        editTownHallSuccess: true,
        editTownHallError: false
      };
    }

    case Types.EDIT_TOWNHALLS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
        editTownHallSuccess: false,
        editTownHallError: true
      };

    case Types.DELETE_TOWNHALL:
      return {
        ...state,
        loading: true,
        errors: "",
        deleteTownHallSuccess: false,
        deleteTownHallError: false
      };

    case Types.DELETE_TOWNHALL_SUCCESS: {
      const { deletedTownHallId } = action.payload;
      const newTownHallList = state.list.filter(
        townHall => townHall.id !== deletedTownHallId
      );

      return {
        ...state,
        loading: false,
        list: newTownHallList,
        // list: state.list,
        errors: "",
        deleteTownHallSuccess: true,
        deleteTownHallError: false
      };
    }

    case Types.DELETE_TOWNHALL_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
        deleteTownHallSuccess: false,
        deleteTownHallError: true
      };

    case Types.ADD_TOWNHALL_USERS:
      return {
        ...state,
        loading: true,
        errors: "",
        addTownHallUsersSuccess: false,
        addTownHallUsersError: false
      };

    case Types.ADD_TOWNHALL_USERS_SUCCESS: {
      const newIncludedUsers = state.includedUsersList.concat(
        action.payload.includedUsers
      );
      return {
        ...state,
        includedUsersList: newIncludedUsers,
        loading: false,
        errors: "",
        addTownHallUsersSuccess: true,
        addTownHallUsersError: false
      };
    }

    case Types.ADD_TOWNHALL_USERS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
        addTownHallUsersSuccess: false,
        addTownHallUsersError: true
      };

    case Types.ADD_TOWNHALL_CAD:
      return {
        ...state,
        loading: true,
        errors: "",
        addTownHallCadSuccess: false,
        addTownHallCadError: false
      };

    case Types.ADD_TOWNHALL_CAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: "",
        addTownHallCadSuccess: true,
        addTownHallCadError: false
      };
    }

    case Types.ADD_TOWNHALL_CAD_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
        addTownHallCadSuccess: false,
        addTownHallCadError: true
      };

    case Types.REMOVE_TOWNHALL_USERS:
      return {
        ...state,
        loading: true,
        errors: ""
      };

    case Types.REMOVE_TOWNHALL_USERS_SUCCESS: {
      const { removedUser } = action.payload;
      const newIncludedUserList = state.includedUsersList.filter(
        user => user.id !== removedUser[0]
      );
      return {
        ...state,
        includedUsersList: newIncludedUserList,
        loading: false,
        errors: "",
        removeTownHallUsersSuccess: true,
        removeTownHallUsersError: false
      };
    }

    case Types.REMOVE_TOWNHALL_USERS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
        removeTownHallUsersSuccess: false,
        removeTownHallUsersError: true
      };

    case Types.GET_TOWNHALL_WALLET:
      return {
        ...state,
        loading: true,
        errors: ""
      };

    case Types.GET_TOWNHALL_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        townHallWallet: action.payload.townHallWallet,
        errors: ""
      };

    case Types.GET_TOWNHALL_WALLET_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      };

    case Types.GET_TOWNHALL_CADS:
      return {
        ...state,
        loading: true,
        errors: ""
      };

    case Types.GET_TOWNHALL_CADS_SUCCESS:
      return {
        ...state,
        loading: false,
        townHallCads: action.payload.townHallCads,
        townHallCadsCount: action.payload.townHallCadsCount,
        errors: ""
      };

    case Types.GET_TOWNHALL_CADS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      };

    case Types.GET_TOWNHALL_REVENUES_REPORT:
      return {
        ...state,
        loading: true,
        errors: ""
      };

    case Types.GET_TOWNHALL_REVENUES_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        townHallRevenuesReport: action.payload.townHallRevenuesReport,
        errors: ""
      };

    case Types.GET_TOWNHALL_REVENUES_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      };

    case Types.RESET_TOWNHALL_STATUS:
      return {
        ...state,
        addTownHallSuccess: false,
        addTownHallError: false,
        deleteTownHallSuccess: false,
        deleteTownHallError: false,
        editTownHallSuccess: false,
        editTownHallError: false,
        addTownHallUsersSuccess: false,
        addTownHallUsersError: false,
        addTownHallCadSuccess: false,
        addTownHallCadError: false,
        getTownHallCadsSuccess: false,
        getTownHallCadsError: false,
      }
    default:
      return { ...state };
  }
};

export const getTownHalls = ({
  pageSize = "",
  ativo = "",
  cod = "",
  cnpj = "",
  cidade = "",
  page = "",
  estadoId = "",
  stateName = ""
}) => {
  return {
    type: Types.GET_TOWNHALLS,
    payload: { pageSize, cod, ativo, cnpj, cidade, estadoId, page, stateName }
  }
};

export const getIncludedUsers = ({
  pageSize = "",
  townHallId = "",
  gestor = "",
  page = "",
  cod = "",
  name = "",
  cpf = "",
  perfil = ""
}) => ({
  type: Types.GET_INCLUDED_USERS,
  payload: {
    pageSize,
    townHallId,
    gestor,
    cod,
    page,
    name,
    cpf,
    perfil
  }
});

export const addTownHall = townHall => ({
  type: Types.ADD_TOWNHALLS,
  payload: townHall
});

export const addTownHallUsers = users => ({
  type: Types.ADD_TOWNHALL_USERS,
  payload: {
    users
  }
});

export const getUniqueTownHall = id => ({
  type: Types.GET_UNIQUE_TOWNHALL,
  payload: { id }
});

export const deleteTownHall = id => ({
  type: Types.DELETE_TOWNHALL,
  payload: { id }
});

export const editTownHall = townHall => ({
  type: Types.EDIT_TOWNHALLS,
  payload: { townHall }
});

export const editTownHallSuccess = townHall => ({
  type: Types.EDIT_TOWNHALLS_SUCCESS,
  payload: { townHall }
});

export const removeTownHallUsers = users => ({
  type: Types.REMOVE_TOWNHALL_USERS,
  payload: { users }
});

export const getTownHallWallet = id => ({
  type: Types.GET_TOWNHALL_WALLET,
  payload: { id }
});

export const resetTownhallStatus = () => ({
  type: Types.RESET_TOWNHALL_STATUS,
});

export const addTownHallCad = ({ prefeituraId, quantidade }) => ({
  type: Types.ADD_TOWNHALL_CAD,
  payload: {
    prefeituraId,
    quantidade
  }
})

export const getTownHallCads = ({ prefeituraId, codigoLote, id, quantidade, page, pageSize, lotCode }) => ({
  type: Types.GET_TOWNHALL_CADS,
  payload: {
    prefeituraId,
    codigoLote,
    page,
    pageSize,
    id,
    quantidade,
    lotCode
  }
})

export const getTownHallRevenuesReport = ({ prefeituraId, tipoComprador, dataInicio, dataTermino }) => ({
  type: Types.GET_TOWNHALL_REVENUES_REPORT,
  payload: {
    prefeituraId,
    tipoComprador,
    dataInicio,
    dataTermino
  }
})




import { all, call, put, takeLatest } from "redux-saga/effects";
import { Types } from "./index";
import {setTownHall} from '../menu/actions'

import {
  getTownHalls,
  getUniqueTownHall,
  addTownHall,
  deleteTownHall,
  getIncludedUsers,
  editTownHall,
  addTownHallUsers,
  removeTownHallUsers,
  getTownHallWallet,
  createLotCode,
  getTownHallCads,
  createTownHallCad,
  getTownHallRevenuesReport
} from "../../services/http/index";

function* getTownHallsAsync({ payload }) {
  try {
    const townHallList = yield call(getTownHalls, payload);
    console.log(townHallList.data);
    yield put({
      type: Types.GET_TOWNHALLS_SUCCESS,
      payload: {
        list: townHallList.data.data,
        count: townHallList.data.count
      }
    });

  } catch (error) {
    yield put({
      type: Types.GET_TOWNHALLS_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* getUniqueTownHallAsync({ payload }) {
  try {
    const townHall = yield call(getUniqueTownHall, payload);
    yield put({
      type: Types.GET_UNIQUE_TOWNHALL_SUCCESS,
      payload: {
        townHall: townHall.data.data
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_UNIQUE_TOWNHALL_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* getIncludedUsersAsync({ payload }) {
  try {
    const usersList = yield call(getIncludedUsers, payload);

    yield put({
      type: Types.GET_INCLUDED_USERS_SUCCESS,
      payload: {
        includedUsersList: usersList.data.data,
        count: usersList.data.count
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_INCLUDED_USERS_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* addTownHallAsync({ payload }) {
  try {
    const townHall = yield call(addTownHall, payload);
    yield put({
      type: Types.ADD_TOWNHALLS_SUCCESS
    });
  } catch (error) {
    yield put({
      type: Types.ADD_TOWNHALLS_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* addTownHallUsersAsync({ payload }) {
  try {
    const usersList = yield call(addTownHallUsers, payload);

    yield put({
      type: Types.ADD_TOWNHALL_USERS_SUCCESS,
      payload: {
        includedUsers: usersList.data.data
      }
    });
  } catch (error) {
    yield put({
      type: Types.ADD_TOWNHALL_USERS_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* removeTownHallUsersAsync({ payload }) {
  try {
    const response = yield call(removeTownHallUsers, payload);
    yield put({
      type: Types.REMOVE_TOWNHALL_USERS_SUCCESS,
      payload: {
        removedUser: payload.users
      }
    });
  } catch (error) {
    yield put({
      type: Types.REMOVE_TOWNHALL_USERS_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* deleteTownHallAsync({ payload }) {
  try {
    const deletedTownHall = yield call(deleteTownHall, payload);
    yield put({
      type: Types.DELETE_TOWNHALL_SUCCESS,
      payload: {
        deletedTownHallId: payload.id
      }
    });
  } catch (error) {
    yield put({
      type: Types.DELETE_TOWNHALL_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* editTownHallAsync({ payload }) {
  try {
    const response = yield call(editTownHall, payload);
  
    yield put({
      type: Types.EDIT_TOWNHALLS_SUCCESS,
      payload: {
        townHall: response.data.data
      }
    });

    // yield put(setTownHall(response.data.data));
  } catch (error) {
    yield put({
      type: Types.EDIT_TOWNHALLS_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* getTownHallWalletAsync({ payload }) {
  try {
    const response = yield call(getTownHallWallet, payload);

    yield put({
      type: Types.GET_TOWNHALL_WALLET_SUCCESS,
      payload: {
        townHallWallet: response.data.data
      }
    });
  } catch (error) {
    yield put({
      type: Types.GET_TOWNHALL_WALLET_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* addTownHallCadAsync({ payload }) {
  try {
    const codigoLote = yield call(createLotCode, { prefeituraId: payload.prefeituraId });

    yield call(createTownHallCad, { ...payload, codigoLote: codigoLote.data.data })

    yield put({
      type: Types.ADD_TOWNHALL_CAD_SUCCESS,
      payload: {
      }
    });
  } catch (error) {
    yield put({
      type: Types.ADD_TOWNHALL_CAD_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* getTownHallCadsAsync({ payload }) {
  try {
    const response = yield call(getTownHallCads, payload);

    yield put({
      type: Types.GET_TOWNHALL_CADS_SUCCESS,
      payload: {
        townHallCads: response.data.data,
        townHallCadsCount: response.data.count
      }
    });

  } catch (error) {
    yield put({
      type: Types.GET_TOWNHALL_CADS_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

function* getTownHallRevenuesReportAsync({ payload }) {
  try {
    const response = yield call(getTownHallRevenuesReport, payload);

    yield put({
      type: Types.GET_TOWNHALL_REVENUES_REPORT_SUCCESS,
      payload: {
        townHallRevenuesReport: response.data.data,
      }
    });

  } catch (error) {
    yield put({
      type: Types.GET_TOWNHALL_REVENUES_REPORT_ERROR,
      payload: {
        errors: error.response.data.errors
      }
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(Types.GET_TOWNHALLS, getTownHallsAsync),
    yield takeLatest(Types.GET_UNIQUE_TOWNHALL, getUniqueTownHallAsync),
    yield takeLatest(Types.ADD_TOWNHALLS, addTownHallAsync),
    yield takeLatest(Types.DELETE_TOWNHALL, deleteTownHallAsync),
    yield takeLatest(Types.EDIT_TOWNHALLS, editTownHallAsync),
    yield takeLatest(Types.GET_INCLUDED_USERS, getIncludedUsersAsync),
    yield takeLatest(Types.ADD_TOWNHALL_USERS, addTownHallUsersAsync),
    yield takeLatest(Types.REMOVE_TOWNHALL_USERS, removeTownHallUsersAsync),
    yield takeLatest(Types.GET_TOWNHALL_WALLET, getTownHallWalletAsync),
    yield takeLatest(Types.ADD_TOWNHALL_CAD, addTownHallCadAsync),
    yield takeLatest(Types.GET_TOWNHALL_CADS, getTownHallCadsAsync),
    yield takeLatest(Types.GET_TOWNHALL_REVENUES_REPORT, getTownHallRevenuesReportAsync),
  ]);
}

export const Types = {
  LOGIN_USER: "auth/LOGIN_USER",
  LOGIN_USER_SUCCESS: "auth/LOGIN_USER_SUCCESS",
  LOGIN_USER_ERROR: "auth/LOGIN_USER_ERROR",

  REGISTER_USER: "auth/REGISTER_USER",
  REGISTER_USER_SUCCESS: "auth/REGISTER_USER_SUCCESS",
  REGISTER_USER_ERROR: "auth/REGISTER_USER_ERROR",

  FORGOT_PASSWORD: "auth/FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS: "auth/FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERROR: "auth/FORGOT_PASSWORD_ERROR",

  RESET_PASSWORD: "auth/RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "auth/RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "auth/RESET_PASSWORD_ERROR",

  LOGOUT_USER: "auth/LOGOUT_USER",
  GET_PERMISSIONS: "auth/GET_PERMISSIONS",
  GET_PERMISSIONS_SUCCESS: "auth/GET_PERMISSIONS_SUCCESS",
  SET_PERMISSIONS: "auth/SET_PERMISSIONS",
};

const INIT_STATE = {
  user: localStorage.getItem("token"),
  permissions: [],
  forgotUserMail: "",
  newPassword: "",
  resetPasswordCode: "",
  loading: false,
  error: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.LOGIN_USER:
      return { ...state, loading: true, error: "" };

    case Types.LOGIN_USER_SUCCESS:
      return { ...state, loading: false, 
        user: action.payload.access_token, 
        permissions: action.payload.permissions,
        error: "" 
      };

    case Types.GET_PERMISSIONS_SUCCESS: 
      return { ...state, permissions: action.payload, loading:false, error: ""};

    case Types.LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: "",
        error: action.payload.message
      };
    case Types.FORGOT_PASSWORD:
      return { ...state, loading: true, error: "" };
    case Types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: ""
      };
    case Types.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: "",
        error: action.payload.message
      };
    case Types.RESET_PASSWORD:
      return { ...state, loading: true, error: "" };
    case Types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: action.payload,
        resetPasswordCode: "",
        error: ""
      };
    case Types.RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: "",
        resetPasswordCode: "",
        error: action.payload.message
      };
    case Types.REGISTER_USER:
      return { ...state, loading: true, error: "" };
    case Types.REGISTER_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload.uid, error: "" };
    case Types.REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: "",
        error: action.payload.message
      };
    case Types.LOGOUT_USER:
      return { ...state, user: null, error: "" };
    
    default:
      return { ...state };
  }
};

export const loginUser = (user, history) => ({
  type: Types.LOGIN_USER,
  payload: { user, history }
});
export const loginUserSuccess = user => ({
  type: Types.LOGIN_USER_SUCCESS,
  payload: user
});
export const loginUserError = message => ({
  type: Types.LOGIN_USER_ERROR,
  payload: { message }
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: Types.FORGOT_PASSWORD,
  payload: { forgotUserMail, history }
});
export const forgotPasswordSuccess = forgotUserMail => ({
  type: Types.FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail
});
export const forgotPasswordError = message => ({
  type: Types.FORGOT_PASSWORD_ERROR,
  payload: { message }
});

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
  type: Types.RESET_PASSWORD,
  payload: { resetPasswordCode, newPassword, history }
});
export const resetPasswordSuccess = newPassword => ({
  type: Types.RESET_PASSWORD_SUCCESS,
  payload: newPassword
});
export const resetPasswordError = message => ({
  type: Types.RESET_PASSWORD_ERROR,
  payload: { message }
});

export const registerUser = (user, history) => ({
  type: Types.REGISTER_USER,
  payload: { user, history }
});
export const registerUserSuccess = user => ({
  type: Types.REGISTER_USER_SUCCESS,
  payload: user
});
export const registerUserError = message => ({
  type: Types.REGISTER_USER_ERROR,
  payload: { message }
});

export const logoutUser = history => ({
  type: Types.LOGOUT_USER,
  payload: { history }
});

export const getPermissions = () => ({
  type: Types.GET_PERMISSIONS,
});

export const setPermissions = permissions => ({
  type: Types.GET_PERMISSIONS_SUCCESS,
  payload:permissions
});
